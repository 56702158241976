import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllStudents } from '../reducers/StudentSlice';
import { fetchAllRooms } from '../reducers/RoomSlice';
import { createBooking } from '../reducers/BookingSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

function AddBookings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [selectedRoomId, setSelectedRoomId] = useState('');
  const [totalPayment, setTotalPayment] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [advancedPayment, setAdvancedPayment] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState('months');
  const [selectedValue, setSelectedValue] = useState(1);
  const { students } = useSelector((state) => state.students);
  const { rooms } = useSelector((state) => state.rooms);

  useEffect(() => {
    dispatch(fetchAllStudents());
    dispatch(fetchAllRooms());
  }, [dispatch]);

  useEffect(() => {
    if (selectedRoomId) {
      const room = rooms.find((room) => room._id === selectedRoomId);
      const dailyRate = room ? room.monthlyRent / 30 : 0;
      const weeklyRate = dailyRate * 7;

      let total = 0;
      if (selectedDuration === 'days') {
        total = dailyRate * selectedValue;
      } else if (selectedDuration === 'weeks') {
        total = weeklyRate * selectedValue;
      } else if (selectedDuration === 'months') {
        total = room ? room.monthlyRent * selectedValue : 0;
      }

      setTotalPayment(total);
      setRemainingAmount(total - (parseFloat(advancedPayment) || 0));
    }
  }, [selectedRoomId, selectedDuration, selectedValue, advancedPayment, rooms]);

  // useEffect(() => {
  //   const room = rooms.find((room) => room._id === selectedRoomId);
  //   if (room && selectedMonths) {
  //     const total = room.monthlyRent * selectedMonths;
  //     setTotalPayment(total);
  //     setRemainingAmount(total - advancedPayment);
  //   }
  // }, [rooms, selectedRoomId, selectedMonths, advancedPayment]);

  const handleStudentChange = (event) => {
    setSelectedStudentId(event.target.value);
  };

  const handleRoomChange = (event) => {
    setSelectedRoomId(event.target.value);
  };

  const handleDurationTypeChange = (event) => {
    setSelectedDuration(event.target.value);
    setSelectedValue(1);
  };

  const handleAdvancedPaymentChange = (event) => {
    const advance = parseFloat(event.target.value) || 0;
    if (advance < 0) {
      alert("Advanced payment cannot be less than 0");
    } else if (advance > totalPayment) {
      alert("Advanced payment cannot be greater than total payment");
    } else {
      setAdvancedPayment(advance);
      setRemainingAmount(totalPayment - advance);
    }
  };
  const handleValueChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedValue(value);
  };
  // const handleTotalPaymentChange = (event) => {
  //   const totalMonths = parseInt(event.target.value, 10);
  //   setSelectedMonths(totalMonths);
  // };

  const selectedStudent = students.find((student) => student._id === selectedStudentId);
  const selectedRoom = rooms.find((room) => room._id === selectedRoomId);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const paymentHistoryRecord = [
        {
          amount: advancedPayment,
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          month: new Date().getMonth() + 1,
        },
      ];

      const bookingDate = new Date(e.target.bookingDate?.value);
      const selectedStudentName = students.find(student => student._id === selectedStudentId)?.studentName;

      const bookingData = {
        studentName: selectedStudentName,
        studentId: selectedStudentId,
        selectedRoom,
        advancedPayment,
        totalPayment,
        remainingAmount,
        durationType: selectedDuration,
        durationValue: selectedValue,
        securityCharges: e.target.securityCharges.value,
        bookingDate: bookingDate.toISOString(),
        securityPaymentMode: e.target.securityPaymentMode.value,
        paymentMode: e.target.paymentMode.value,
        paymentStatus: e.target.paymentStatus.value,
        notes: e.target.notes.value,
        paymentHistory: paymentHistoryRecord,
      };

      const response = await dispatch(createBooking(bookingData)).unwrap();
      if (response) {
        navigate('/bookings');
        toast.success('Booking has been added!');
      }
    } catch (error) {
      toast.error('Failed to add booking. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="container mt-3 text-white py-3">
      <h2 className="text-center mb-4">Booking Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="studentName" className="form-label">Student Name</label>
            <select
              className="form-select"
              id="studentName"
              value={selectedStudentId}
              onChange={handleStudentChange}
            >
              <option value="" disabled>Select student</option>
              {students?.map((student) => (
                <option key={student._id} value={student._id}>
                  {student.studentName}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="studentId" className="form-label">Student ID</label>
            <input
              type="text"
              className="form-control"
              id="studentId"
              value={selectedStudentId}
              readOnly
            />
          </div>


          <div className="col-md-4">
            <label htmlFor="roomNumber" className="form-label">Room Number</label>
            <select
              className="form-select"
              id="roomNumber"
              value={selectedRoomId}
              onChange={handleRoomChange}
            >
              <option value="" disabled>Select a room</option>
              {rooms.map((room) => (
                <option key={room._id} value={room._id}>
                  {room.roomNumber}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="roomType" className="form-label">Room Type</label>
            <input
              type="text"
              className="form-control"
              id="roomType"
              value={selectedRoom?.roomType || ''}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="roomPrice" className="form-label">Room Price/Month</label>
            <input
              type="text"
              className="form-control"
              id="roomPrice"
              value={selectedRoom?.monthlyRent || ''}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="bookingDate" className="form-label">Booking Date</label>
            <input
              type="date"
              className="form-control"
              id="bookingDate"
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="durationType" className="form-label">Duration Type</label>
            <select
              className="form-select"
              id="durationType"
              value={selectedDuration}
              onChange={handleDurationTypeChange}
            >
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="durationValue" className="form-label">Duration Value</label>
            <input
              type="number"
              className="form-control"
              id="durationValue"
              value={selectedValue}
              onChange={handleValueChange}
              min={1}
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="advancedPayment" className="form-label">Advanced Payment</label>
            <input
              type="number"
              className="form-control"
              id="advancedPayment"
              value={advancedPayment}
              onChange={handleAdvancedPaymentChange}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="remainingAmount" className="form-label">Remaining Amount</label>
            <input
              type="text"
              className="form-control"
              id="remainingAmount"
              value={remainingAmount}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="totalPayment" className="form-label">Total Payment</label>
            <input
              type="text"
              className="form-control"
              id="totalPayment"
              value={totalPayment}
              readOnly
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="securityCharges" className="form-label">Security Charges</label>
            <input
              type="number"
              className="form-control"
              id="securityCharges"
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="securityPaymentMode" className="form-label"> Security Payment Mode</label>
            <select
              className="form-select"
              id="securityPaymentMode"
            >
              <option value="" disabled>Select payment method</option>
              <option value="cash">Cash</option>
              <option value="bank_transfer">Bank Transfer</option>
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="paymentMode" className="form-label">Payment Mode</label>
            <select
              className="form-select"
              id="paymentMode"
            >
              <option value="" disabled>Select payment method</option>
              <option value="cash">Cash</option>
              <option value="bank_transfer">Bank Transfer</option>
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="paymentStatus" className="form-label">Payment Status</label>
            <select
              className="form-select"
              id="paymentStatus"
            >
              <option value="" disabled>Select payment status</option>
              <option value="paid">Paid</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div className="col-md-12">
            <label htmlFor="notes" className="form-label">Notes</label>
            <textarea
              className="form-control"
              id="notes"
              rows="3"
            ></textarea>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type="submit"
            className="btn btn-primary text-white"
            disabled={loading}
          >
            {loading ? (
              <span>
                <span className="spinner-border spinner-border-sm me-2"></span>
                Adding...
              </span>
            ) : (
              'Add Booking'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddBookings;

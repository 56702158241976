import { configureStore } from '@reduxjs/toolkit';
import StudentReducer from '../reducers/StudentSlice';
import RoomReducer from '../reducers/RoomSlice';
import BookingReducer from '../reducers/BookingSlice';
import StaffReducer from '../reducers/StaffSlice';
import WorkerReducer from '../reducers/WorkerSlice';


export const store = configureStore({
    reducer: {
        students: StudentReducer,
        rooms: RoomReducer,
        bookings: BookingReducer,
        staffs: StaffReducer,
        workers: WorkerReducer,
    },
});
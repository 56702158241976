import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { fetchStudentById } from '../reducers/StudentSlice';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

const ViewStudents = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { student, status, error } = useSelector(state => state.students);

    useEffect(() => {
        if (id) {
            dispatch(fetchStudentById(id));
        }
    }, [id, dispatch]);

    if (status === 'loading') {
        return <div className="d-flex justify-content-center align-items-center h-100"><CircularProgress /></div>;
    }

    if (status === 'failed') {
        return <div>Failed to load student data: {error}</div>;
    }

    if (!student) {
        return <div>No student data available.</div>;
    }

    return (
        <div className="container my-4 text-white view-student">
            <div className="text-center mb-4">
                <h3 className="fw-bold">Student Data</h3>
            </div>  
            <div className="row" id="studentContainer">
                <div className="col">
                    <div className="card-body">
                        <div className="card student-card p-4">
                            <h2 className='text-center text-white mb-2'>AK Boys Hostel</h2>
                            <div className="d-flex align-items-center mb-3">
                                <img
                                    src={student.image ? `https://ak-boys-hostel-server.onrender.com/uploads/${student.image}` : 'https://via.placeholder.com/100'}
                                    alt="Student Picture"
                                    className="me-3 rounded"
                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                                <div>
                                    <h5 className="card-title mb-0">{student.studentName}</h5>
                                    <p className="text-mute mb-0">{student.department}</p>
                                </div>
                            </div>
                            <div className="same-divs"><strong>Student ID:</strong> {student._id || 'N/A'}</div>
                            <div className="same-divs"><strong>Father's Name:</strong> {student.fatherName || 'N/A'}</div>
                            <div className="same-divs"><strong>Email:</strong> {student.email || 'N/A'}</div>
                            <div className="same-divs"><strong>Date of Birth:</strong> {student.dob ? new Date(student.dob).toLocaleDateString() : 'N/A'}</div>
                            <div className="same-divs"><strong>CNIC:</strong> {student.cnic || 'N/A'}</div>
                            <div className="same-divs"><strong>Gender:</strong> {student.gender || 'N/A'}</div>
                            <div className="same-divs"><strong>City:</strong> {student.city || 'N/A'}</div>
                            <div className="same-divs"><strong>Contact:</strong> {student.contactNumber || 'N/A'}</div>
                            <div className="same-divs"><strong>Joining Date:</strong> {student.joinDate ? new Date(student.joinDate).toLocaleDateString() : 'N/A'}</div>
                            <div className="same-divs"><strong>Address:</strong> {student.currentAddress || 'N/A'}</div>
                            <div className="same-divs"><strong>Permanent Address:</strong> {student.permanentAddress || 'N/A'}</div>
                            <div className="same-divs"><strong>Emergency Contact:</strong> {student.emergencyContact || 'N/A'}</div>
                            <div className="same-divs"><strong>Year:</strong> {student.currentYear || 'N/A'}</div>
                            <div className="same-divs"><strong>Semester:</strong> {student.semester || 'N/A'}</div>
                            <div className="same-divs"><strong>Blood Group:</strong> {student.bloodGroup || 'N/A'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewStudents;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import service from '../api/ApiRoutes';

const initialState = {
  students: [],
  student: null,
  totalStudents: 0,
  totalPages: 1,
  currentPage: 1,
  status: 'idle',
  error: null,
  successMessage: '',
  searchQuery: '',
};

// Create a new student with image upload
export const createStudent = createAsyncThunk(
  'students/createStudent',
  async (studentData, thunkAPI) => {
    try {
      const response = await service.createStudent(studentData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch all students with search and pagination
export const fetchStudents = createAsyncThunk(
  'students/fetchStudents',
  async ({ page, searchQuery }, thunkAPI) => {
    try {
      const response = await service.getStudents({ page, searchTerm: searchQuery });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

//Get All Students
export const fetchAllStudents = createAsyncThunk(
  'students/fetchAllStudents',
  async (config, thunkAPI) => {
    try {
      const response = await service.getAllStudents(config);
      return response.data;
    } catch (error) {
      console.error('API error:', error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single student by ID
export const fetchStudentById = createAsyncThunk(
  'students/fetchStudentById',
  async (studentId, thunkAPI) => {
    try {
      const response = await service.getStudentById(studentId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Update a student
export const updateStudent = createAsyncThunk(
  'students/updateStudent',
  async ({ id, studentData, config }, thunkAPI) => {
    try {
      const response = await service.updateStudent(id, studentData, config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Delete a student
export const deleteStudent = createAsyncThunk(
  'students/deleteStudent',
  async (studentId, thunkAPI) => {
    try {
      await service.deleteStudent(studentId);
      return studentId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Slice
const studentSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    resetState(state) {
      state.error = null;
      state.successMessage = '';
      state.status = 'idle';
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    removeStudent(state, action) {
      state.students = state.students.filter(student => student._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      // Create student with image upload
      .addCase(createStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createStudent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.students.push(action.payload);
        state.successMessage = 'Student created successfully!';
      })
      .addCase(createStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Fetch all students with pagination and search
      .addCase(fetchStudents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.students = action.payload.students;
        state.totalPages = action.payload.totalPages;
        state.totalStudents = action.payload.totalStudents;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Fetch all students 
      .addCase(fetchAllStudents.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAllStudents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.students = Array.isArray(action.payload)
          ? action.payload
          : action.payload?.students || [];
      })
      .addCase(fetchAllStudents.rejected, (state, action) => {
        state.status = 'failed';
        console.error('fetchAllStudents rejected error:', action.payload);
        state.error = action.payload?.message || 'Failed to fetch students';
      })

      // Fetch single student by ID
      .addCase(fetchStudentById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStudentById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.student = action.payload;
      })
      .addCase(fetchStudentById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Update student
      .addCase(updateStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.students.findIndex((s) => s._id === action.payload._id);
        if (index !== -1) {
          state.students[index] = action.payload;
        }
        state.successMessage = 'Student updated successfully!';
      })
      .addCase(updateStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Delete student
      .addCase(deleteStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.students = state.students.filter((s) => s._id !== action.payload);
        state.successMessage = 'Student deleted successfully!';
      })
      .addCase(deleteStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { resetState, setSearchQuery, setCurrentPage } = studentSlice.actions;
export default studentSlice.reducer;

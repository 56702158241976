import React, { useEffect, useState } from 'react'
import { FaTrashCan } from 'react-icons/fa6'
import { MdOutlineKeyboardAlt, MdOutlineBorderColor } from "react-icons/md"
import '../assets/css/Student.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteStudent, fetchStudents, setCurrentPage } from '../reducers/StudentSlice'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import nodata from '../assets/images/nodata.webp'

const Students = () => {  
  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState(false)
  const [searchQuery, setSearchQuery] = useState("");
  const [studentToDelete, setStudentToDelete] = useState(null)
  const { students, totalPages, totalStudents, currentPage, status } = useSelector(state => state.students)

  useEffect(() => {
    dispatch(fetchStudents({ page: currentPage, searchQuery }));
  }, [dispatch, currentPage, searchQuery]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      dispatch(setCurrentPage(newPage))
    }
  }
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    dispatch(fetchStudents({ page: 1, searchQuery: event.target.value }));
  };
  const handleDeleteClick = (studentId) => {
    setStudentToDelete(studentId)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setStudentToDelete(null)
  }
  const handleConfirmDelete = async () => {
    if (studentToDelete) {
      try {
        await dispatch(deleteStudent(studentToDelete)).unwrap();
        await dispatch(fetchStudents({ page: currentPage, searchQuery: '' }));
        toast.success('Student deleted successfully!');
      } catch (error) {
        toast.error('Failed to delete the student. Please try again.');
      } finally {
        setOpenDialog(false);
        setStudentToDelete(null);
      }
    }
  };

  return (
    <>
      <div className='student-container'>
        <div className="table-header d-flex justify-content-between align-items-center p-3">
          <div className="total-count">
            <h6 className='mb-0'>Total Students ({totalStudents})</h6>
          </div>
          <div className="search-box">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="add-btn">
            <Link to={"/students/add-students"}><button className="btn btn-primary text-white">Add Students</button></Link>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">CNIC</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {status === 'loading' ? (
                <tr>
                  <td colSpan="6" className="loading-container">
                    <CircularProgress />
                  </td>
                </tr>
              ) : students.length > 0 ? (
                students.map((student, index) => (
                  <tr key={student._id}>
                    <td scope="row">{(currentPage - 1) * 10 + index + 1}</td>
                    <td>{student.studentName}</td>
                    <td>{student.email}</td>
                    <td>{student.contactNumber}</td>
                    <td>{student.cnic}</td>
                    <td className="action-flex">
                      <Link to={`/students/view-student/${student._id}`}>
                        <MdOutlineKeyboardAlt className="keyboard" />
                      </Link>
                      <Link to={`/students/edit-student/${student._id}`}>
                        <MdOutlineBorderColor className="edit" />
                      </Link>
                      <FaTrashCan className="delete" onClick={() => handleDeleteClick(student._id)} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="no-data">
                    <img src={nodata} alt="No Data" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination-1 d-flex justify-content-center gap-3 align-items-center">
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1 || status === 'loading'}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages || status === 'loading'}
        >
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this student?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Students

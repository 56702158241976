import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { toast } from "react-toastify"
import { FaTrashCan } from 'react-icons/fa6'
import { MdOutlineKeyboardAlt, MdOutlineBorderColor } from "react-icons/md"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { deleteWorker, fetchWorkers, setCurrentPage } from '../reducers/WorkerSlice'
import nodata from '../assets/images/nodata.webp'
import CircularProgress from '@mui/material/CircularProgress';

const Workers = () => {
  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState(false)
  const [searchQuery, setSearchQuery] = useState("");
  const [workerToDelete, setWorkerToDelete] = useState(null)
  const { workers, totalPages, totalWorkers, currentPage, status } = useSelector(state => state.workers)

  useEffect(() => {
    dispatch(fetchWorkers({ page: currentPage, searchQuery }));
  }, [dispatch, currentPage, searchQuery]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      dispatch(setCurrentPage(newPage))
    }
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    dispatch(fetchWorkers({ page: 1, searchQuery: event.target.value }));
  };

  const handleDeleteClick = (workerId) => {
    setWorkerToDelete(workerId)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setWorkerToDelete(null)
  }

  const handleConfirmDelete = async () => {
    if (workerToDelete) {
      try {
        await dispatch(deleteWorker(workerToDelete)).unwrap();
        await dispatch(fetchWorkers({ page: currentPage, searchQuery: '' }));
        toast.success('Worker deleted successfully!');
      } catch (error) {
        toast.error('Failed to delete the worker. Please try again.');
      } finally {
        setOpenDialog(false);
        setWorkerToDelete(null);
      }
    }
  };

  return (
    <>
      <div className="table-header d-flex justify-content-between mt-3 p-3">
        <div className="total-count">
          <h6>Total Workers ({totalWorkers})</h6>
        </div>
        <div className="search-box">
          <div className="search-box">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="add-btn">
          <Link to={"/workers/add-workers"}><button className="btn btn-primary text-white">Add Worker</button></Link>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr #</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">CNIC</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {status === 'loading' ? (
              <tr>
                <td colSpan="6" className="loading-container">
                  <CircularProgress />
                </td>
              </tr>
            ) : workers.length > 0 ? (
              workers.map((worker, index) => (
                <tr key={worker._id}>
                  <td scope="row">{(currentPage - 1) * 10 + index + 1}</td>
                  <td>{worker.fullName}</td>
                  <td>{worker.email}</td>
                  <td>{worker.phone}</td> 
                  <td>{worker.cnic}</td>
                  <td className="action-flex">
                    <Link to={`/workers/view-worker/${worker._id}`}>
                      <MdOutlineKeyboardAlt className="keyboard" />
                    </Link>
                    <Link to={`/workers/edit-worker/${worker._id}`}>
                      <MdOutlineBorderColor className="edit" />
                    </Link>
                    <FaTrashCan className="delete" onClick={() => handleDeleteClick(worker._id)} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="no-data">
                  <img src={nodata} alt="No Data" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-1 d-flex justify-content-center gap-3 align-items-center">
        <button className="pagination-btn" onClick={() => handlePageChange(currentPage - 1)}>
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button className="pagination-btn" onClick={() => handlePageChange(currentPage + 1)}>
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Worker?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Workers;

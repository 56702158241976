import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../screens/Dashboard.js';
import Students from '../screens/Students.js';
import Rooms from '../screens/Rooms.js';
import Bookings from '../screens/Bookings.js';
import AddStudent from '../screens/AddStudent.js';
import AddRooms from '../screens/AddRooms.js';
import UpdateRooms from '../screens/UpdateRooms.js';
import AddBookings from '../screens/AddBookings.js';
import UpdateBookings from '../screens/UpdateBookings.js';
import Profile from '../screens/Profile.js';
import Maintenance from '../screens/Maintenance.js';
import Workers from '../screens/Workers.js';
import AddWorkers from '../screens/AddWorkers.js';
import AddStaff from '../screens/AddStaff.js';
import Staff from '../screens/Staff.js';
import AddMaintenance from '../screens/AddMaintenance.js';
import ViewStudents from '../screens/ViewStudents.js';
import EditStudent from '../screens/EditStudent.js';
import '../assets/css/MainContent.css'
import NotFound from '../screens/NotFound.js';
import ViewRooms from '../screens/ViewRooms.js';
import ViewBookings from '../screens/ViewBookings.js';
import ViewStaff from '../screens/ViewStaff.js';
import UpdateStaff from '../screens/UpdateStaff.js';
import ViewWorker from '../screens/ViewWorker.js';
import UpdateWorker from '../screens/UpdateWorker.js';
import Salary from '../screens/Salary.js';
import AddSalary from '../screens/AddSalary.js';

const MainContent = () => {
    return (
        <>
            <div style={{ flex: 1, width: 100, backgroundColor: '#060C1F' }}>
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/students" element={<Students />} />
                    <Route path="/students/add-students" element={<AddStudent />} />
                    <Route path="/students/view-student/:id" element={<ViewStudents />} />
                    <Route path="/students/edit-student/:id" element={<EditStudent />} />
                    <Route path="/rooms" element={<Rooms />} />
                    <Route path="/rooms/add-rooms" element={<AddRooms />} />
                    <Route path="/rooms/edit-room/:id" element={<UpdateRooms />} />
                    <Route path="/rooms/view-room/:id" element={<ViewRooms />} />
                    <Route path="/bookings" element={<Bookings />} />
                    <Route path="/bookings/add-bookings" element={<AddBookings />} />
                    <Route path="/bookings/edit-booking/:id" element={<UpdateBookings />} />
                    <Route path="/bookings/view-booking/:id" element={<ViewBookings />} />
                    <Route path="/salaries" element={<Salary />} />
                    <Route path="/salaries/add-salary" element={<AddSalary />} />
                    <Route path="/staffs" element={<Staff />} />
                    <Route path="/staffs/add-staff" element={<AddStaff />} />
                    <Route path="/staffs/view-staff/:id" element={<ViewStaff />} />
                    <Route path="/staffs/edit-staff/:id" element={<UpdateStaff />} />
                    <Route path="/workers" element={<Workers />} />
                    <Route path="/workers/add-workers" element={<AddWorkers />} />
                    <Route path="/workers/view-worker/:id" element={<ViewWorker />} />
                    <Route path="/workers/view-worker/:id" element={<ViewWorker />} />
                    <Route path="/workers/edit-worker/:id" element={<UpdateWorker />} />
                    <Route path="/maintenances" element={<Maintenance />} />
                    <Route path="/maintenances/add-maintenances" element={<AddMaintenance />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </div>
        </>
    )
}


export default MainContent
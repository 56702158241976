import axiosInstance from './AxiosInstance';
import apiRoutes from '../Constant';

const service = {
  //Auth
  register: (data) => axiosInstance.post(`${apiRoutes.auth}/register`, data),
  login: (data) => axiosInstance.post(`${apiRoutes.auth}/login`, data),
  getAuthById: (id, config) => axiosInstance.get(`${apiRoutes.auth}/get-auth/${id}`, config),
  updateAuth: (id, data, config) => axiosInstance.put(`${apiRoutes.auth}/update-auth/${id}`, data, config),
  deleteAuth: (id, config) => axiosInstance.delete(`${apiRoutes.auth}/delete-auth/${id}`, config),

  // Students
  createStudent: (data, config) => axiosInstance.post(`${apiRoutes.students}/add-student`, data, config),
  getStudents: (params) => axiosInstance.get(`${apiRoutes.students}/get-students`, { params }),
  getAllStudents: (config) => axiosInstance.get(`${apiRoutes.students}/get-all-students`, config),
  getStudentById: (id, config) => axiosInstance.get(`${apiRoutes.students}/get-student/${id}`, config),
  updateStudent: (id, data, config) => axiosInstance.put(`${apiRoutes.students}/update-student/${id}`, data, config),
  deleteStudent: (id, config) => axiosInstance.delete(`${apiRoutes.students}/delete-student/${id}`, config),

  // Rooms
  createRoom: (data, config) => axiosInstance.post(`${apiRoutes.rooms}/add-room`, data, config),
  getRooms: (params) => axiosInstance.get(`${apiRoutes.rooms}/get-rooms`, { params }),
  getAllRooms: (config) => axiosInstance.get(`${apiRoutes.rooms}/get-all-rooms`, config),
  getRoomById: (id, config) => axiosInstance.get(`${apiRoutes.rooms}/get-room/${id}`, config),
  updateRoom: (id, data, config) => axiosInstance.put(`${apiRoutes.rooms}/update-room/${id}`, data, config),
  deleteRoom: (id, config) => axiosInstance.delete(`${apiRoutes.rooms}/delete-room/${id}`, config),

  // Bookings
  createBooking: (data, config) => axiosInstance.post(`${apiRoutes.bookings}/add-booking`, data, config),
  getBookings: (params) => axiosInstance.get(`${apiRoutes.bookings}/get-bookings`, { params }),
  getAllBookings: (config) => axiosInstance.get(`${apiRoutes.bookings}/get-all-bookings`, config),
  getBookingById: (id, config) => axiosInstance.get(`${apiRoutes.bookings}/get-booking/${id}`, config),
  updateBooking: (id, data, config) => axiosInstance.put(`${apiRoutes.bookings}/update-booking/${id}`, data, config),
  deleteBooking: (id, config) => axiosInstance.delete(`${apiRoutes.bookings}/delete-booking/${id}`, config),

  // Salaries
  createSalary: (data, config) => axiosInstance.post(`${apiRoutes.salaries}/add-salary`, data, config),
  getSalaries: (params) => axiosInstance.get(`${apiRoutes.salaries}/get-salaries`, { params }),
  getAllSalaries: (config) => axiosInstance.get(`${apiRoutes.salaries}/get-all-salaries`, config),
  getSalaryById: (id, config) => axiosInstance.get(`${apiRoutes.salaries}/get-salary/${id}`, config),
  updateSalary: (id, data, config) => axiosInstance.put(`${apiRoutes.salaries}/update-salary/${id}`, data, config),
  deleteSalary: (id, config) => axiosInstance.delete(`${apiRoutes.salaries}/delete-salary/${id}`, config),

  // Staffs
  createStaff: (staffData, config) => axiosInstance.post(`${apiRoutes.staffs}/add-staff`, staffData, config),
  getStaffMembers: (params) => axiosInstance.get(`${apiRoutes.staffs}/get-staffs`, { params }),
  getAllStaffMembers: (config) => axiosInstance.get(`${apiRoutes.staffs}/get-all-staffs`, config),
  getStaffById: (id, config) => axiosInstance.get(`${apiRoutes.staffs}/get-staff/${id}`, config),
  updateStaff: (id, staffData, config) => axiosInstance.put(`${apiRoutes.staffs}/update-staff/${id}`, staffData, config),
  deleteStaff: (id, config) => axiosInstance.delete(`${apiRoutes.staffs}/delete-staff/${id}`, config),

  //Workkers
  createWorker: (data, config) => axiosInstance.post(`${apiRoutes.workers}/add-worker`, data, config),
  getWorkers: (params) => axiosInstance.get(`${apiRoutes.workers}/get-workers`, { params }),
  getAllWorkers: (config) => axiosInstance.get(`${apiRoutes.workers}/get-all-workers`, config),
  getWorkerById: (id, config) => axiosInstance.get(`${apiRoutes.workers}/get-worker/${id}`, config),
  updateWorker: (id, data, config) => axiosInstance.put(`${apiRoutes.workers}/update-worker/${id}`, data, config),
  deleteWorker: (id, config) => axiosInstance.delete(`${apiRoutes.workers}/delete-worker/${id}`, config),
};

export default service;

import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import { MdOutlinePaid, MdOutlineBed, MdOutlineFactCheck, MdPersonOutline } from "react-icons/md";
import { fetchAllStudents } from '../reducers/StudentSlice';
import { fetchAllRooms } from '../reducers/RoomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBookings } from '../reducers/BookingSlice';
import { fetchAllStaffMembers } from '../reducers/StaffSlice';
import { fetchAllWorkers } from '../reducers/WorkerSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { students } = useSelector((state) => state.students);
  const { rooms } = useSelector((state) => state.rooms);
  const { bookings } = useSelector((state) => state.bookings);
  const { staffMembers } = useSelector((state) => state.staffs);
  const { workers } = useSelector((state) => state.workers);
  const [selectedPaymentModeIncome, setSelectedPaymentModeIncome] = useState('all');
  const [selectedPaymentModeSecurity, setSelectedPaymentModeSecurity] = useState('all');

  useEffect(() => {
    dispatch(fetchAllStudents());
    dispatch(fetchAllRooms());
    dispatch(getAllBookings());
    dispatch(fetchAllWorkers());
    dispatch(fetchAllStaffMembers());
  }, [dispatch]);

  const totalIncomeByMode = (paymentMode) => {
    return bookings?.reduce((total, booking) => {
      if (paymentMode === 'all') {
        return total + (booking?.paymentHistory?.reduce((sum, payment) => sum + (payment?.amount || 0), 0) || 0);
      }
      if (booking.paymentMode === paymentMode) {
        return total + (booking?.paymentHistory?.reduce((sum, payment) => sum + (payment?.amount || 0), 0) || 0);
      }
      return total;
    }, 0);
  };

  const securityChargesByMode = (paymentMode) => {
    return bookings?.reduce((total, booking) => {
      if (paymentMode === 'all') {
        return total + (booking.securityCharges || 0);
      }
      return total + (booking.securityPaymentMode === paymentMode ? booking.securityCharges || 0 : 0);
    }, 0);
  };

  const handlePaymentModeIncomeChange = (e) => {
    setSelectedPaymentModeIncome(e.target.value);
  };

  const handlePaymentModeSecurityChange = (e) => {
    setSelectedPaymentModeSecurity(e.target.value);
  };

  const totalIncome = totalIncomeByMode(selectedPaymentModeIncome);
  const selectedSecurityCharges = securityChargesByMode(selectedPaymentModeSecurity);

  return (
    <div className='container-fluid dashboard'>
      <Row className="my-4 text-white">
        <h3 className='mb-4'>Overview</h3>

        <Col md={3} className="dash-boxes mb-5">
          <Form.Select
            className="mb-3"
            value={selectedPaymentModeIncome}
            onChange={handlePaymentModeIncomeChange}
          >
            <option value="all">All</option>
            <option value="cash">Cash</option>
            <option value="bank_transfer">Bank Transfer</option>
          </Form.Select>
          <div className="content border rounded d-flex justify-content-between p-4 bg-primary">
            <div className="text-box text-white">
              <h6>Total Income</h6>
              <span>{totalIncome?.toLocaleString()} PKR</span>
            </div>
            <div className="icon-box d-flex align-items-center">
              <MdOutlinePaid size={80} />
            </div>
          </div>
        </Col>

        <Col md={3} className="dash-boxes mb-5">
          <div className="content border rounded d-flex justify-content-between p-4 bg-warning">
            <div className="text-box text-white">
              <h6>Total Students</h6>
              <span>{students?.length} Students</span>
            </div>
            <div className="icon-box d-flex align-items-center">
              <MdPersonOutline size={80} />
            </div>
          </div>
        </Col>

        <Col md={3} className="dash-boxes mb-5">
          <div className="content border rounded d-flex justify-content-between p-4 bg-success">
            <div className="text-box text-white">
              <h6>Total Rooms</h6>
              <span>{rooms?.length} Rooms</span>
            </div>
            <div className="icon-box d-flex align-items-center">
              <MdOutlineBed size={80} />
            </div>
          </div>
        </Col>

        <Col md={3} className="dash-boxes mb-5">
          <div className="content border rounded d-flex justify-content-between p-4 bg-secondary">
            <div className="text-box text-white">
              <h6>Total Bookings</h6>
              <span>{bookings?.length} Bookings</span>
            </div>
            <div className="icon-box d-flex align-items-center">
              <MdOutlineFactCheck size={80} />
            </div>
          </div>
        </Col>

        <Col md={3} className="dash-boxes mb-5">
          <Form.Select
            className="mb-3"
            value={selectedPaymentModeSecurity}
            onChange={handlePaymentModeSecurityChange}
          >
            <option value="all">All</option>
            <option value="cash">Cash</option>
            <option value="bank_transfer">Bank Transfer</option>
          </Form.Select>
          <div className="content border rounded d-flex justify-content-between p-4 bg-info">
            <div className="text-box text-white mb-3">
              <h6>Security Charges</h6>
              <span>{selectedSecurityCharges?.toLocaleString()} PKR</span>
            </div>
            <div className="icon-box d-flex align-items-center">
              <MdOutlinePaid size={80} />
            </div>
          </div>
        </Col>

        <Col md={3} className="dash-boxes mb-5">
          <div className="content border rounded d-flex justify-content-between p-4 bg-secondary">
            <div className="text-box text-white">
              <h6>Total Staffs</h6>
              <span>{staffMembers?.length} Staffs</span>
            </div>
            <div className="icon-box d-flex align-items-center">
              <MdPersonOutline size={80} />
            </div>
          </div>
        </Col>

        <Col md={3} className="dash-boxes mb-5">
          <div className="content border rounded d-flex justify-content-between p-4 bg-primary">
            <div className="text-box text-white">
              <h6>Total Workers</h6>
              <span>{workers?.length} Workers</span>
            </div>
            <div className="icon-box d-flex align-items-center">
              <MdPersonOutline size={80} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { fetchStaffById, updateStaff } from '../reducers/StaffSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
const UpdateStaff = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { staffMember, status, error } = useSelector(state => state.staffs);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    dob: '',
    designation: '',
    joinDate: '',
    gender: '',
    salary: '',
    address: '',
    cnic: '',
    department: '',
    image: null,
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchStaffById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (staffMember) {
      setFormData({
        fullName: staffMember.fullName || '',
        email: staffMember.email || '',
        phone: staffMember.phone || '',
        dob: staffMember.dob || '',
        designation: staffMember.designation || '',
        joinDate: staffMember.joinDate || '',
        gender: staffMember.gender || '',
        salary: staffMember.salary || '',
        address: staffMember.address || '',
        cnic: staffMember.cnic || '',
        department: staffMember.department || '',
        image: null,
      });
    }
  }, [staffMember]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const staffData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === 'image' && formData[key]) {
        staffData.append('image', formData[key]);
      } else {
        staffData.append(key, formData[key]);
      }
    });

    try {
      await dispatch(updateStaff({ id, staffData }));
      toast.success('Staff updated successfully!');
      navigate('/staffs');
    } catch (error) {
      toast.error('Failed to update staff. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (status === 'loading') {
    return <div>Loading staff data...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error || 'Failed to load staff data.'}</div>;
  }


  return (
    <>
      <div className="container mt-4 text-white">
        <h2 className="mb-4 text-center">Update Staff</h2>
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="fullName" className="form-label">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                placeholder="Enter full name"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter email"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Enter phone number"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="dob" className="form-label">Date of Birth</label>
              <input
                type="date"
                className="form-control"
                id="dob"
                value={formData.dob ? new Date(formData.dob).toISOString().split('T')[0] : ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="designation" className="form-label">Designation</label>
              <input
                type="text"
                className="form-control"
                id="designation"
                value={formData.designation}
                onChange={handleInputChange}
                placeholder="Enter designation"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="joinDate" className="form-label">Joining Date</label>
              <input
                type="date"
                className="form-control"
                id="joinDate"
                value={formData.joinDate ? new Date(formData.joinDate).toISOString().split('T')[0] : ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="gender" className="form-label">Gender</label>
              <select
                className="form-select"
                id="gender"
                value={formData.gender}
                onChange={handleInputChange}
              >
                <option value="">Select gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="salary" className="form-label">Salary</label>
              <input
                type="number"
                className="form-control"
                id="salary"
                value={formData.salary}
                onChange={handleInputChange}
                placeholder="Enter salary"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="address" className="form-label">Address</label>
              <input
                type="text"
                className="form-control"
                id="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Enter address"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="cnic" className="form-label">CNIC/ID Number</label>
              <input
                type="text"
                className="form-control"
                id="cnic"
                value={formData.cnic}
                onChange={handleInputChange}
                placeholder="Enter ID number"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="department" className="form-label">Department</label>
              <input
                type="text"
                className="form-control"
                id="department"
                value={formData.department}
                onChange={handleInputChange}
                placeholder="Enter department"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="image" className="form-label">Photo</label>
              <input
                type="file"
                className="form-control"
                id="image"
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary text-white"
                disabled={loading}
              >
                {loading ? (
                  <span>
                    <span className="spinner-border spinner-border-sm me-2"></span>
                    Updating...
                  </span>
                ) : (
                  'Update Staff'
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default UpdateStaff

import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { fetchWorkerById } from '../reducers/WorkerSlice';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

const ViewWorker = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { worker, status, error } = useSelector(state => state.workers);

    useEffect(() => {
        if (id) {
            dispatch(fetchWorkerById(id));
        }
    }, [id, dispatch]);

    if (status === 'loading') {
        return <div className="d-flex justify-content-center align-items-center h-100"><CircularProgress /></div>;
    }

    if (status === 'failed') {
        return <div>Failed to load worker data: {error}</div>;
    }

    if (!worker) {
        return <div>No worker data available.</div>;
    }

    return (
        <div className="container my-4 text-white view-worker">
            <div className="text-center mb-4">
                <h3 className="fw-bold">Worker Data</h3>
            </div>
            <div className="row" id="workerContainer">
                <div className="col">
                    <div className="card-body">
                        <div className="card worker-card p-4">
                            <h2 className='text-center text-white mb-2'>AK Boys Hostel</h2>
                            <div className="d-flex align-items-center mb-3">
                                <img
                                    src={worker?.image ? `https://ak-boys-hostel-server.onrender.com/uploads/${worker?.image}` : 'https://via.placeholder.com/100'}
                                    alt="Worker Picture"
                                    className="me-3 rounded"
                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                                <div>
                                    <h5 className="card-title mb-0">{worker?.fullName}</h5>
                                    <p className="mb-0 text-white">{worker?.department}</p>
                                </div>
                            </div>
                            <div className="same-divs"><strong>Worker ID:</strong> {worker?._id || 'N/A'}</div>
                            <div className="same-divs"><strong>Email:</strong> {worker?.email || 'N/A'}</div>
                            <div className="same-divs"><strong>Date of Birth:</strong> {worker?.dob ? new Date(worker?.dob).toLocaleDateString() : 'N/A'}</div>
                            <div className="same-divs"><strong>CNIC:</strong> {worker?.cnic || 'N/A'}</div>
                            <div className="same-divs"><strong>Gender:</strong> {worker?.gender || 'N/A'}</div>
                            <div className="same-divs"><strong>Job Role:</strong> {worker?.jobRole || 'N/A'}</div>
                            <div className="same-divs"><strong>Shift Timing:</strong> {worker?.shiftTiming || 'N/A'}</div>
                            <div className="same-divs"><strong>Salary:</strong> {worker?.salary || 'N/A'}</div>
                            <div className="same-divs"><strong>Contact:</strong> {worker?.phone || 'N/A'}</div>
                            <div className="same-divs"><strong>Joining Date:</strong> {worker?.joinDate ? new Date(worker?.joinDate).toLocaleDateString() : 'N/A'}</div>
                            <div className="same-divs"><strong>Address:</strong> {worker?.address || 'N/A'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewWorker;

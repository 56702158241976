import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import { createWorker } from '../reducers/WorkerSlice';
import { toast } from 'react-toastify';

function AddWorkers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    address: '',
    cnic: '',
    jobRole: '',
    department: '',
    joinDate: '',
    shiftTiming: '',
    salary: '',
    image: null,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    setFormData({
      ...formData,
      [id]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const workerData = new FormData();
  
    Object.keys(formData).forEach((key) => {
      if (key === 'photo' && formData[key]) {
        workerData.append('photo', formData[key]);
      } else {
        workerData.append(key, formData[key]);
      }
    });

    try {
      await dispatch(createWorker(workerData));  
      setLoading(false);
      navigate('/workers');  
      toast.success('Worker added successfully!');
    } catch (error) {
      setLoading(false);
      toast.error('Failed to add worker. Please try again.');
    }
  };

  return (
    <div className="container mt-4 text-white">
      <h2 className="mb-4 text-center">Add Worker</h2>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="fullName" className="form-label">Full Name</label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              placeholder="Enter full name"
              value={formData.fullName}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="phone" className="form-label">Phone</label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              placeholder="Enter phone number"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="dob" className="form-label">Date of Birth</label>
            <input
              type="date"
              className="form-control"
              id="dob"
              value={formData.dob}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="gender" className="form-label">Gender</label>
            <select
              className="form-select"
              id="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <option value="">Select gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="address" className="form-label">Address</label>
            <input
              type="text"
              className="form-control"
              id="address"
              placeholder="Enter address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="jobRole" className="form-label">Job Role</label>
            <input
              type="text"
              className="form-control"
              id="jobRole"
              placeholder="Enter job role"
              value={formData.jobRole}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="department" className="form-label">Department</label>
            <input
              type="text"
              className="form-control"
              id="department"
              placeholder="Enter department"
              value={formData.department}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="joinDate" className="form-label">Joining Date</label>
            <input
              type="date"
              className="form-control"
              id="joinDate"
              value={formData.joinDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="shiftTiming" className="form-label">Shift Timing</label>
            <input
              type="text"
              className="form-control"
              id="shiftTiming"
              placeholder="e.g., 9:00 AM - 5:00 PM"
              value={formData.shiftTiming}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="salary" className="form-label">Salary</label>
            <input
              type="number"
              className="form-control"
              id="salary"
              placeholder="Enter salary"
              value={formData.salary}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="cnic" className="form-label">CNIC/ID Number</label>
            <input
              type="text"
              className="form-control"
              id="cnic"
              placeholder="Enter ID number"
              value={formData.cnic}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="image" className="form-label">Photo</label>
            <input
              type="file"
              className="form-control"
              id="image"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary text-white"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <span className="spinner-border spinner-border-sm me-2"></span>
                  Adding...
                </span>
              ) : (
                'Add Worker'
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddWorkers;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import service from '../api/ApiRoutes';

const initialState = {
  workers: [],
  worker: null,
  totalWorkers: 0,
  totalPages: 1,
  currentPage: 1,
  status: 'idle',
  error: null,
  successMessage: '',
  searchQuery: '',
};

// Create a new worker with image upload
export const createWorker = createAsyncThunk(
  'workers/createWorker',
  async (workerData, thunkAPI) => {
    try {
      const response = await service.createWorker(workerData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch all workers with search and pagination
export const fetchWorkers = createAsyncThunk(
  'workers/fetchWorkers',
  async ({ page, searchQuery }, thunkAPI) => {
    try {
      const response = await service.getWorkers({ page, searchTerm: searchQuery });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

// Get All Workers
export const fetchAllWorkers = createAsyncThunk(
  'workers/fetchAllWorkers',
  async (config, thunkAPI) => {
    try {
      const response = await service.getAllWorkers(config);
      return response.data;
    } catch (error) {
      console.error('API error:', error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single worker by ID
export const fetchWorkerById = createAsyncThunk(
  'workers/fetchWorkerById',
  async (workerId, thunkAPI) => {
    try {
      const response = await service.getWorkerById(workerId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Update a worker
export const updateWorker = createAsyncThunk(
  'workers/updateWorker',
  async ({ id, data, config }, thunkAPI) => {
    try {
      const response = await service.updateWorker(id, data, config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Delete a worker
export const deleteWorker = createAsyncThunk(
  'workers/deleteWorker',
  async (workerId, thunkAPI) => {
    try {
      await service.deleteWorker(workerId);
      return workerId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Slice
const workerSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    resetState(state) {
      state.error = null;
      state.successMessage = '';
      state.status = 'idle';
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    removeWorker(state, action) {
      state.workers = state.workers.filter(worker => worker._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      // Create worker with image upload
      .addCase(createWorker.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createWorker.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workers.push(action.payload);
        state.successMessage = 'Worker created successfully!';
      })
      .addCase(createWorker.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Fetch all workers with pagination and search
      .addCase(fetchWorkers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workers = action.payload.workers;
        state.totalPages = action.payload.totalPages;
        state.totalWorkers = action.payload.totalWorkers;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchWorkers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Fetch all workers 
      .addCase(fetchAllWorkers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAllWorkers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workers = Array.isArray(action.payload)
          ? action.payload
          : action.payload?.workers || [];
      })
      .addCase(fetchAllWorkers.rejected, (state, action) => {
        state.status = 'failed';
        console.error('fetchAllWorkers rejected error:', action.payload);
        state.error = action.payload?.message || 'Failed to fetch workers';
      })

      // Fetch single worker by ID
      .addCase(fetchWorkerById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkerById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worker = action.payload;
      })
      .addCase(fetchWorkerById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Update worker
      .addCase(updateWorker.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateWorker.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.workers.findIndex((w) => w._id === action.payload._id);
        if (index !== -1) {
          state.workers[index] = action.payload;
        }
        state.successMessage = 'Worker updated successfully!';
      })
      .addCase(updateWorker.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Delete worker
      .addCase(deleteWorker.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteWorker.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workers = state.workers.filter((w) => w._id !== action.payload);
        state.successMessage = 'Worker deleted successfully!';
      })
      .addCase(deleteWorker.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { resetState, setSearchQuery, setCurrentPage } = workerSlice.actions;
export default workerSlice.reducer;

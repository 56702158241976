import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createRoom } from '../reducers/RoomSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

function AddRooms() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    roomNumber: '',
    roomType: '',
    floorNumber: '',
    capacity: '',
    availableBeds: '',
    facilities: [],
    monthlyRent: '',
    additionalCharges: '',
    roomStatus: '',
    availableFrom: '',
    notes: '',
  });

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        facilities: checked
          ? [...prev.facilities, id]
          : prev.facilities.filter((facility) => facility !== id),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
  
    try {
      const response = await dispatch(createRoom(formData));
  
      if (response) {
        navigate('/rooms');
        toast.success('Room has been added!');
      } else {
        toast.error('An error occurred while adding the room.');
      }
    } catch (error) {
      toast.error('An error occurred while adding the room.');
    } finally {
      setLoading(false);
      setFormData({
        roomNumber: '',
        roomType: '',
        floorNumber: '',
        capacity: '',
        availableBeds: '',
        facilities: [],
        monthlyRent: '',
        additionalCharges: '',
        roomStatus: '',
        availableFrom: '',
        notes: '',
      }); 
    }
  };
  

  return (
    <div className="container mt-3 text-white py-3">
      <h2 className="text-center mb-4">Add Rooms</h2>
      <form onSubmit={handleSubmit}>
        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="roomNumber" className="form-label">Room Number</label>
            <select
              className="form-control"
              id="roomNumber"
              value={formData.roomNumber}
              onChange={handleChange}
            >
              <option value="">Select a room</option>
              {Array.from({ length: 36 }, (_, index) => {
                const roomNumber = index + 1;
                const isVip = [1, 13, 14, 26, 27].includes(roomNumber);
                return (
                  <option key={roomNumber} value={`Room ${roomNumber}`}>
                    {isVip ? `Room ${roomNumber} (VIP)` : `Room ${roomNumber}`}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="roomType" className="form-label">Room Type</label>
            <select
              className="form-select"
              id="roomType"
              value={formData.roomType}
              onChange={handleChange}
            >
              <option value="" disabled>Select room type</option>
              <option value="single">Single Occupancy</option>
              <option value="double">Double Occupancy</option>
              <option value="triple">Triple Occupancy</option>
              <option value="fourth">Fourth Occupancy</option>
              <option value="fifth">Fifth Occupancy</option>
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="floorNumber" className="form-label">Floor Number</label>
            <select
              className="form-select"
              id="floorNumber"
              value={formData.floorNumber}
              onChange={handleChange}
            >
              <option value="" disabled>Select floor</option>
              <option value="ground">Ground Floor</option>
              <option value="first">First Floor</option>
              <option value="second">Second Floor</option>
            </select>
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="capacity" className="form-label">Maximum Capacity</label>
            <input
              type="number"
              className="form-control"
              id="capacity"
              value={formData.capacity}
              onChange={handleChange}
              placeholder="Enter maximum capacity"
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="availableBeds" className="form-label">Available Beds</label>
            <input
              type="number"
              className="form-control"
              id="availableBeds"
              value={formData.availableBeds}
              onChange={handleChange}
              placeholder="Enter available beds"
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-12">
            <label className="form-label">Room Facilities</label>
            {['AC', 'Bathroom', 'Wifi', 'Study Table', 'Wardrobe', 'Geezer', 'Toilet'].map((facility) => (
              <div className="form-check" key={facility}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={facility}
                  checked={formData.facilities.includes(facility)}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor={`facility${facility}`}>
                  {facility}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-6">
            <label htmlFor="monthlyRent" className="form-label">Monthly Rent</label>
            <input
              type="number"
              className="form-control"
              id="monthlyRent"
              value={formData.monthlyRent}
              onChange={handleChange}
              placeholder="Enter monthly rent"
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-6">
            <label htmlFor="roomStatus" className="form-label">Room Status</label>
            <select
              className="form-select"
              id="roomStatus"
              value={formData.roomStatus}
              onChange={handleChange}
            >
              <option value="" disabled>Select status</option>
              <option value="available">Available</option>
              <option value="occupied">Occupied</option>
              <option value="maintenance">Under Maintenance</option>
            </select>
          </div>

          <div className="col-md-6">
            <label htmlFor="availableFrom" className="form-label">Available From</label>
            <input
              type="date"
              className="form-control"
              id="availableFrom"
              value={formData.availableFrom}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-12">
            <label htmlFor="notes" className="form-label">Notes/Description</label>
            <textarea
              className="form-control"
              id="notes"
              value={formData.notes}
              onChange={handleChange}
              rows="3"
              placeholder="Enter any additional details"
            ></textarea>
          </div>
        </div>

        <div className="row g-3">
          <div className="col-md-12 d-flex justify-content-end gap-3">
            <button type="reset" className="btn btn-secondary text-white">
              Reset
            </button>
            <button
            type="submit"
            className="btn btn-primary text-white"
            disabled={loading}
          >
            {loading ? (
              <span>
                <span className="spinner-border spinner-border-sm me-2"></span>
                Adding...
              </span>
            ) : (
              'Add Room'
            )}
          </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddRooms;

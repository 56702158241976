import React, { useEffect, useState } from 'react';
import { FaTrashCan } from 'react-icons/fa6';
import { MdOutlineKeyboardAlt, MdOutlineBorderColor } from "react-icons/md";
import { Link } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRoom, fetchRooms, setCurrentPage } from '../reducers/RoomSlice';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import nodata from '../assets/images/nodata.webp'
import { getAllBookings } from '../reducers/BookingSlice';

const Rooms = () => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [roomToDelete, setRoomToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { rooms, totalPages, totalRooms, currentPage, status } = useSelector(state => state.rooms);
  const { bookings } = useSelector((state) => state.bookings);

  useEffect(() => {
    dispatch(fetchRooms({ page: currentPage, searchQuery }));
  }, [dispatch, currentPage, searchQuery]);


  useEffect(() => {
    dispatch(getAllBookings());
  }, [dispatch]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      dispatch(setCurrentPage(newPage));
    }
  };

  const calculateRemainingCapacity = (roomId, capacity) => {
    const roomBookings = bookings?.filter(booking => booking.selectedRoom === roomId) || [];
    return capacity - roomBookings.length;
  };


  const handleCloseDialog = () => {
    setOpenDialog(false);
    setRoomToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (roomToDelete) {
      try {
        await dispatch(deleteRoom(roomToDelete)).unwrap();
        await dispatch(fetchRooms({ page: currentPage, searchQuery: '' }));
        toast.success('Room deleted successfully!');
      } catch (error) {
        toast.error('Failed to delete the room. Please try again.');
      } finally {
        setOpenDialog(false);
        setRoomToDelete(null);
      }
    }
  };

  const handleDeleteClick = (roomId) => {
    setRoomToDelete(roomId)
    setOpenDialog(true)
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    dispatch(fetchRooms({ page: 1, searchQuery: event.target.value }));
  };

  return (
    <>
      <div className="room-container">
        <div className="table-header d-flex justify-content-between align-items-center p-3">
          <div className="total-count">
            <h6 className='mb-0'>Total Rooms ({totalRooms})</h6>
          </div>
          <div className="search-box">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="add-btn">
            <Link to="/rooms/add-rooms">
              <button className="btn btn-primary text-white">Add Room</button>
            </Link>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Room No</th>
                <th scope="col">Floor No</th>
                <th scope="col">Total Capacity</th>
                <th scope="col">Remaining Capacity</th>
                <th scope="col">Room Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {status === 'loading' ? (
                <tr>
                  <td colSpan="7" className="loading-container">
                    <CircularProgress />
                  </td>
                </tr>
              ) : rooms?.length > 0 ? (
                rooms.map((room, index) => {
                  const remainingCapacity = calculateRemainingCapacity(room._id, room.capacity);
                  const roomStatus = remainingCapacity === 0 ? 'Occupied' : 'Available';
                  let statusColor = '';
                  switch (room?.roomStatus?.toLowerCase()) {
                    case 'available':
                      statusColor = 'green';
                      break;
                    case 'occupied':
                      statusColor = 'red';
                      break;
                    case 'maintenance':
                      statusColor = 'orange';
                      break;
                    default:
                      statusColor = 'gray';
                  }
                  return (
                    <tr key={room._id || `room-${index}`}>
                      <td scope="row">{(currentPage - 1) * 10 + index + 1}</td>
                      <td>{room.roomNumber}</td>
                      <td>{room.floorNumber}</td>
                      <td>{room.capacity}</td>
                      <td>{remainingCapacity}</td>
                      <td style={{ textTransform: 'capitalize', color: statusColor }}>
                        {roomStatus}
                      </td>
                      <td className="action-flex">
                        <Link to={`/rooms/view-room/${room._id}`}>
                          <MdOutlineKeyboardAlt className="keyboard" />
                        </Link>
                        <Link to={`/rooms/edit-room/${room._id}`}>
                          <MdOutlineBorderColor className="edit" />
                        </Link>
                        <FaTrashCan className="delete" onClick={() => handleDeleteClick(room._id)} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" className="no-data">
                    <img src={nodata} alt="No Data" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className="pagination-1 d-flex justify-content-center gap-3 align-items-center">
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this room?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Rooms;

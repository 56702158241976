import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchStaffById } from '../reducers/StaffSlice';
import { useEffect } from 'react';

const ViewStaff = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { staffMember, status, error } = useSelector(state => state.staffs);

    useEffect(() => {
        if (id) {
            dispatch(fetchStaffById(id));
        }
    }, [id, dispatch]);

    if (status === 'loading') {
        return <div className="d-flex justify-content-center align-items-center h-100"><CircularProgress /></div>;
    }

    if (status === 'failed') {
        return <div>Failed to load staff data: {error}</div>;
    }

    if (!staffMember) {
        return <div>No staff available.</div>;
    }
    
    return (
        <>
            <div className="container my-4 text-white view-staff">
                <div className="text-center mb-4">
                    <h3 className="fw-bold">Staff Data</h3>
                </div>
                <div className="row" id="staffContainer">
                    <div className="col">
                        <div className="card-body">
                            <div className="card staff-card p-4">
                                <h2 className='text-center text-white mb-2'>AK Boys Hostel</h2>
                                <div className="d-flex align-items-center mb-3">
                                    <img
                                        src={staffMember.image && staffMember.image !== 'null' ?
                                            `http://localhost:8000/uploads/${staffMember.image}` :
                                            'https://via.placeholder.com/100'}
                                            alt="Staff Picture"
                                            className="me-3 rounded"
                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                    />
                                    <div>
                                        <h5 className="card-title mb-0">{staffMember.fullName || 'N/A'}</h5>
                                        <p className="text-mute mb-0">{staffMember.department || 'N/A'}</p>
                                    </div>
                                </div>
                                <div className="same-divs"><strong>Staff ID:</strong> {staffMember._id || 'N/A'}</div>
                                <div className="same-divs"><strong>Email:</strong> {staffMember.email || 'N/A'}</div>
                                <div className="same-divs"><strong>Date of Birth:</strong> {staffMember.dob ? new Date(staffMember.dob).toLocaleDateString() : 'N/A'}</div>
                                <div className="same-divs"><strong>CNIC:</strong> {staffMember.cnic || 'N/A'}</div>
                                <div className="same-divs"><strong>Gender:</strong> {staffMember.gender || 'N/A'}</div>
                                <div className="same-divs"><strong>City:</strong> {staffMember.address || 'N/A'}</div>
                                <div className="same-divs"><strong>Contact:</strong> {staffMember.phone || 'N/A'}</div>
                                <div className="same-divs"><strong>Joining Date:</strong> {staffMember.joinDate ? new Date(staffMember.joinDate).toLocaleDateString() : 'N/A'}</div>
                                <div className="same-divs"><strong>Address:</strong> {staffMember.address || 'N/A'}</div>
                                <div className="same-divs"><strong>Salary:</strong> {staffMember.salary || 'N/A'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewStaff;

import React, { useEffect, useState } from 'react'
import '../assets/css/Profile.css';
import profileimage from '../assets/images/logo.jpg';
import { Link } from 'react-router-dom'
function Profile() {
  const [user, setUser] = useState(null);
  const [loginTime, setLoginTime] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem('user');
    const storedLoginTime = localStorage.getItem('loginTime');
    if (userData) {
      setUser(JSON.parse(userData));
    }
    if (storedLoginTime) {
      setLoginTime(storedLoginTime);
    }
  }, []);
  return (
    <>
      <div className='cricketwinbet-profile-main-bg'>
        <div className='cricketwinbet-profile-breadcrumb-bg'>
          <p>Profile Settings</p>
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-4 mb-5'>
              <div className='cricketwinbet-profile-card-bg'>
                <div className='cricketwinbet-profile-card-image'>
                  <img src={profileimage} alt='Profile Image' />
                </div>
                <div className='profile-card-name'>
                  <p>{user?.username}</p>
                </div>
                <div className='profile-card-email mt-3'>
                  <p>{user?.email}</p>
                </div>
                <div className='profile-card-reset-wrapper'>
                  <div className='profile-card-reset-btn'>
                    <Link to={'/email'}> Reset Password</Link>
                  </div>
                </div>
                <div className='profile-card-join-date'>
                    <span>Last Login on {loginTime || 'N/A'}</span>
                </div>
              </div>
            </div>
            <div className='col-md-8'>
              <div className='cricketwinbet-detail-form-bg'>
                <div className='cricketwinbet-detail-heading'>
                  <strong>About You</strong>
                </div>
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <div className='cricketwinbet-detail-form-fields'>
                      <label>Name</label>
                      <br></br>
                      <input type='text' placeholder='User Name' value={user?.username} readOnly />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='cricketwinbet-detail-form-fields'>
                      <label>Email</label>
                      <br></br>
                      <input type='email' placeholder='Email' value={user?.email} readOnly />
                    </div>
                  </div>
                </div>
                {/* <div className='cricketwinbet-detail-save-btn pt-3 d-flex justify-content-center'>
                  <Link>Edit </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
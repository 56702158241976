import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import service from '../api/ApiRoutes';

const initialState = {
  staffMembers: [],
  staffMember: null,
  totalStaff: 0,
  totalPages: 1,
  currentPage: 1,
  status: 'idle',
  error: null,
  successMessage: '',
  searchQuery: '',
};

// Create a new staff member with image upload
export const createStaff = createAsyncThunk(
  'staff/createStaff',
  async (staffData, thunkAPI) => {
    try {
      const response = await service.createStaff(staffData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch all staff members with search and pagination
export const fetchStaffMembers = createAsyncThunk(
  'staff/fetchStaffMembers',
  async ({ page = 1, searchQuery = '' }, thunkAPI) => {
    try {
      const searchTerm = searchQuery.trim();
      const response = await service.getStaffMembers({ page, searchTerm });
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred while fetching staff members';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);


// Fetch all staff members
export const fetchAllStaffMembers = createAsyncThunk(
  'staff/fetchAllStaffMembers',
  async (config, thunkAPI) => {
    try {
      const response = await service.getAllStaffMembers(config);
      return response.data;
    } catch (error) {
      console.error('API error:', error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single staff member by ID
export const fetchStaffById = createAsyncThunk(
  'staff/fetchStaffById',
  async (staffId, thunkAPI) => {
    try {
      const response = await service.getStaffById(staffId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Update a staff member
export const updateStaff = createAsyncThunk(
  'staff/updateStaff',
  async ({ id, staffData, config }, thunkAPI) => {
    try {
      const response = await service.updateStaff(id, staffData, config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Delete a staff member
export const deleteStaff = createAsyncThunk(
  'staff/deleteStaff',
  async (staffId, thunkAPI) => {
    try {
      await service.deleteStaff(staffId);
      return staffId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Slice
const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    resetState(state) {
      state.error = null;
      state.successMessage = '';
      state.status = 'idle';
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    removeStaff(state, action) {
      state.staffMembers = state.staffMembers.filter(
        (staff) => staff._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // Create staff member with image upload
      .addCase(createStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createStaff.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.staffMembers.push(action.payload);
        state.successMessage = 'Staff member created successfully!';
      })
      .addCase(createStaff.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Fetch all staff members with pagination and search
      .addCase(fetchStaffMembers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStaffMembers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.staffMembers = action.payload.staffMembers;
        state.totalPages = action.payload.totalPages;
        state.totalStaff = action.payload.totalStaff;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchStaffMembers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Fetch all staff members
      .addCase(fetchAllStaffMembers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAllStaffMembers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.staffMembers = Array.isArray(action.payload)
          ? action.payload
          : action.payload?.staffMembers || [];
      })
      .addCase(fetchAllStaffMembers.rejected, (state, action) => {
        state.status = 'failed';
        console.error('fetchAllStaffMembers rejected error:', action.payload);
        state.error = action.payload?.message || 'Failed to fetch staff members';
      })

      // Fetch single staff member by ID
      .addCase(fetchStaffById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStaffById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.staffMember = action.payload;
      })
      .addCase(fetchStaffById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Update staff member
      .addCase(updateStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateStaff.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.staffMembers.findIndex(
          (s) => s._id === action.payload._id
        );
        if (index !== -1) {
          state.staffMembers[index] = action.payload;
        }
        state.successMessage = 'Staff member updated successfully!';
      })
      .addCase(updateStaff.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Delete staff member
      .addCase(deleteStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteStaff.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.staffMembers = state.staffMembers.filter(
          (s) => s._id !== action.payload
        );
        state.successMessage = 'Staff member deleted successfully!';
      })
      .addCase(deleteStaff.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { resetState, setSearchQuery, setCurrentPage } = staffSlice.actions;
export default staffSlice.reducer;

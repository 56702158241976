export const baseURL = 'https://ak-boys-hostel-server.onrender.com' + '/api/';
// export const baseURL = 'http://localhost:8000' + '/api/';

const apiRoutes = {
    auth: '/auth',
    rooms: '/rooms',
    students: '/students',
    bookings: '/bookings',
    staffs: '/staffs',
    workers: '/workers',
};

export default apiRoutes;
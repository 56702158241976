import React, { useState } from 'react';
import { Form, Button, Container, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginAuth } from '../reducers/AuthSlice';
import CircularProgress from '@mui/material/CircularProgress';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await dispatch(loginAuth(formData)).unwrap();
      toast.success('Login successful!');
      const loginTime = new Date().toISOString();
      localStorage.setItem('token', result.token);
      localStorage.setItem('user', JSON.stringify(result.user));
      localStorage.setItem('loginTime', loginTime); 
      localStorage.setItem('isAuthenticated', 'true');

      if (result.user.role === 'admin') {
        navigate('/');
      } else {
        navigate('/login');
      }
    } catch (error) {
      toast.error(error.message || 'Login failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid className="auth-container">
      <Row className="h-100">
        <Col className="left w-50 d-flex justify-content-center align-items-center">
          <div className="content">
            <h2 className="text-center text-white">Login</h2>
          </div>
        </Col>
        <Col className="right w-50 d-flex justify-content-center align-items-center">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="my-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter Email..."
              />
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter Password..."
              />
            </Form.Group>
            <Button 
              type="submit" 
              variant="dark" 
              className="my-3" 
              disabled={isLoading}
              style={{ width: '90px' }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" /> 
              ) : (
                'Login'
              )}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllStudents } from '../reducers/StudentSlice';
import { fetchAllRooms } from '../reducers/RoomSlice';
import { useNavigate, useParams } from 'react-router';
import { getBookingById, updateBooking } from '../reducers/BookingSlice';
import { toast } from 'react-toastify';

function UpdateBookings() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    selectedStudentId: '',
    selectedRoomId: '',
    roomType: '',
    roomPrice: '',
    totalPayment: 0,
    remainingAmount: 0,
    totalPaid: 0,
    paymentAmount: 0,
    bookingDate: '',
    totalMonths: 1,
    // durationType: 'months',
    // durationValue: 1,
    paymentMode: '',
    paymentStatus: '',
    notes: '',
    paymentHistory: [],
  });

  const { students } = useSelector((state) => state.students);
  const { rooms } = useSelector((state) => state.rooms);
  const { booking } = useSelector((state) => state.bookings);

  useEffect(() => {
    dispatch(fetchAllStudents());
    dispatch(fetchAllRooms());
    if (id) {
      dispatch(getBookingById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (booking && booking.data) {
      const { data } = booking;
      const totalPaidFromHistory = data.paymentHistory.reduce((total, payment) => total + payment.amount, 0);
  
      setFormData((prev) => ({
        ...prev,
        selectedStudentId: data.studentName || '',
        selectedRoomId: data.selectedRoom || '',
        totalPayment: data.totalPayment || 0,
        remainingAmount: data.totalPayment - totalPaidFromHistory || 0,
        bookingDate: data.bookingDate?.split('T')[0] || '',
        totalPaid: totalPaidFromHistory, 
        // durationType: data?.durationType || 'months',
        // durationValue: data?.durationValue || 1,
        paymentMode: data.paymentMode || '',
        paymentStatus: data.paymentStatus || '',
        notes: data.notes || '',
        paymentHistory: data.paymentHistory || [],
      }));
    }
  }, [booking]);
  

  useEffect(() => {
    if (rooms.length > 0 && formData.selectedRoomId) {
      const room = rooms.find((room) => room._id === formData.selectedRoomId);
      if (room) {
        const total = room.monthlyRent * formData.totalMonths;
        setFormData((prev) => ({
          ...prev,
          totalPayment: total,
          remainingAmount: total - prev.totalPaid,
          roomType: room.roomType,
          roomPrice: room.monthlyRent,
        }));
      }
    }
  }, [formData.selectedRoomId, formData.totalMonths, rooms]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      paymentStatus: prev.remainingAmount > 0 ? 'pending' : 'paid',
    }));
  }, [formData.remainingAmount]);

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === 'advancedPayment') {
      const newPayment = parseFloat(value) || 0;

      setFormData((prev) => {
        const updatedTotalPaid = prev.totalPaid + (newPayment - prev.advancedPayment);
        const updatedRemainingAmount = prev.totalPayment - updatedTotalPaid;

        return {
          ...prev,
          advancedPayment: newPayment,
          totalPaid: updatedTotalPaid,
          remainingAmount: updatedRemainingAmount,
        };
      });
    } else if (id === 'totalMonths') {
      const totalMonths = parseInt(value, 10) || 1;
      const selectedRoom = rooms.find((room) => room._id === formData.selectedRoomId);

      if (selectedRoom) {
        const totalPayment = selectedRoom.monthlyRent * totalMonths;

        setFormData((prev) => ({
          ...prev,
          totalMonths,
          totalPayment,
          remainingAmount: totalPayment - prev.totalPaid,
        }));
      }
    } else if (id === 'totalPaid') {
      const newTotalPaid = parseFloat(value) || 0;
      const updatedRemainingAmount = formData.totalPayment - newTotalPaid;

      setFormData((prev) => ({
        ...prev,
        totalPaid: newTotalPaid,
        remainingAmount: updatedRemainingAmount,
      }));
    } else if (id === 'newPaymentAmount') {
      const newPayment = parseFloat(value) || 0;
      const updatedTotalPaid = formData.totalPaid + newPayment;
      const updatedRemainingAmount = formData.totalPayment - updatedTotalPaid;

      setFormData((prev) => ({
        ...prev,
        newPaymentAmount: newPayment,
        totalPaid: updatedTotalPaid,
        remainingAmount: updatedRemainingAmount,
      }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }
  };

  const addPaymentHistory = (amount) => {
    const currentTime = new Date();
    const paymentDetails = {
      amount,
      date: currentTime.toISOString(),
      time: currentTime.toLocaleTimeString(),
      month: currentTime.toLocaleString('default', { month: 'long' }),
    };
  
    setFormData((prev) => {
      const updatedTotalPaid = prev.totalPaid + amount; 
      return {
        ...prev,
        paymentHistory: [...prev.paymentHistory, paymentDetails],
        totalPaid: updatedTotalPaid,
        remainingAmount: prev.totalPayment - updatedTotalPaid,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formData.paymentAmount > 0) {
      addPaymentHistory(formData.paymentAmount);
    }

    const updatedBooking = {
      selectedStudent: formData.selectedStudentId,
      selectedRoom: formData.selectedRoomId,
      totalPayment: formData.totalPayment,
      remainingAmount: formData.remainingAmount,
      totalPaid: formData.totalPaid,
      bookingDate: formData.bookingDate,
      paymentMode: formData.paymentMode,
      paymentStatus: formData.remainingAmount > 0 ? 'pending' : 'paid',
      notes: formData.notes,
      roomType: formData.roomType,
      roomPrice: formData.roomPrice,
      paymentHistory: formData.paymentHistory,
      paymentAmount: formData.paymentAmount,
      // durationType: formData.durationType,
      // durationValue: formData.durationValue,
    };

    try {
      await dispatch(updateBooking({ id, bookingData: updatedBooking })).unwrap();
      navigate('/bookings');
      toast.success('Booking updated successfully!');
    } catch (error) {
      toast.error('Failed to update booking. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5 text-white py-3">
      <h2 className="text-center mb-4">Update Booking</h2>
      <form onSubmit={handleSubmit}>
        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="selectedStudentId" className="form-label">Student Name</label>
            <select
              className="form-select"
              id="selectedStudentId"
              value={formData.selectedStudentId}
              onChange={handleChange}
            >
              <option value="" disabled>Select a student</option>
              {students.map((student) => (
                <option key={student._id} value={student._id}>
                  {student.studentName}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="selectedRoomId" className="form-label">Room Number</label>
            <select
              className="form-select"
              id="selectedRoomId"
              value={formData.selectedRoomId}
              onChange={handleChange}
            >
              <option value="" disabled>Select a room</option>
              {rooms.map((room) => (
                <option key={room._id} value={room._id}>
                  {room.roomNumber} ({room.roomType})
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="roomType" className="form-label">Room Type</label>
            <input
              type="text"
              className="form-control"
              id="roomType"
              value={formData.roomType}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="roomPrice" className="form-label">Room Price/Month</label>
            <input
              type="text"
              className="form-control"
              id="roomPrice"
              value={formData.roomPrice}
              readOnly
            />
          </div>

          {/* <div className="col-md-4">
            <label htmlFor="durationType" className="form-label">Duration Type</label>
            <select
              className="form-select"
              id="durationType"
              value={formData.durationType}
              onChange={handleChange}
            >
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="durationValue" className="form-label">Duration Value</label>
            <input
              type="number"
              className="form-control"
              id="durationValue"
              value={formData.durationValue}
              onChange={handleChange}
              min={1}
            />
          </div> */}
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="totalPaid" className="form-label">Total Paid Amount</label>
            <input
              type="number"
              className="form-control"
              id="totalPaid"
              value={formData.totalPaid}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="paymentAmount" className="form-label">New Payment Amount</label>
            <input
              type="number"
              className="form-control"
              id="paymentAmount"
              value={formData.paymentAmount}
              onChange={handleChange}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="remainingAmount" className="form-label">Remaining Amount</label>
            <input
              type="text"
              className="form-control"
              id="remainingAmount"
              value={formData.remainingAmount}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="totalPayment" className="form-label">Total Amount</label>
            <input
              type="text"
              className="form-control"
              id="totalPayment"
              value={formData.totalPayment}
              readOnly
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="bookingDate" className="form-label">Booking Date</label>
            <input
              type="date"
              className="form-control"
              id="bookingDate"
              value={formData.bookingDate}
              onChange={handleChange}
            />
          </div>
          {/* <div className="col-md-4">
            <label htmlFor="paymentMode" className="form-label">Payment Mode</label>
            <select
              className="form-select"
              id="paymentMode"
              value={formData.paymentMode}
              onChange={handleChange}
            >
              <option value="cash">Cash</option>
              <option value="bank_transfer">Bank Transfer</option>
            </select>
          </div> */}

          <div className="col-md-4">
            <label htmlFor="paymentStatus" className="form-label">Payment Status</label>
            <input
              type="text"
              className="form-control"
              id="paymentStatus"
              value={formData.paymentStatus}
              readOnly
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="notes" className="form-label">Notes</label>
          <textarea
            className="form-control"
            id="notes"
            rows="3"
            value={formData.notes}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="d-flex justify-content-center">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Updating...' : 'Update Booking'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default UpdateBookings;

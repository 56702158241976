import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SpeedIcon from '@mui/icons-material/Speed';
import SchoolIcon from "@mui/icons-material/School";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import GroupIcon from "@mui/icons-material/Group";
import WorkIcon from "@mui/icons-material/Work";
import PaymentIcon from "@mui/icons-material/Payment";
import BuildIcon from "@mui/icons-material/Build";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import { logout } from "../api/ProtectedRoute";
import '../assets/css/Sidebar.css'

const Sidebar = () => {
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false)
  const [activeLink, setActiveLink] = useState('/');

  const handleLogoutClick = () => {
    setOpenDialog(true)
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleConfirmLogout = () => {
    setOpenDialog(false);
    logout();
  }
  const handleLinkClick = (path) => {
    setActiveLink(path);
  };
  return (
    <>
      <div id="sidebar" className="sidebar">
        <ul className="list-unstyled">
          <li
            className={activeLink === "/" ? "active" : ""}
            onClick={() => handleLinkClick("/")}
          >
            <Link to="/">
              <SpeedIcon style={{ color: "blue" }} /> Dashboard
            </Link>
          </li>
          <li
            className={activeLink === "/students" ? "active" : ""}
            onClick={() => handleLinkClick("/students")}
          >
            <Link to="/students">
              <SchoolIcon style={{ color: "green" }} /> Students
            </Link>
          </li>
          <li
            className={activeLink === "/rooms" ? "active" : ""}
            onClick={() => handleLinkClick("/rooms")}
          >
            <Link to="/rooms">
              <MeetingRoomIcon style={{ color: "purple" }} /> Rooms
            </Link>
          </li>
          <li
            className={activeLink === "/bookings" ? "active" : ""}
            onClick={() => handleLinkClick("/bookings")}
          >
            <Link to="/bookings">
              <BookOnlineIcon style={{ color: "orange" }} /> Booking
            </Link>
          </li>
          <li
            className={activeLink === "/staff" ? "active" : ""}
            onClick={() => handleLinkClick("/staff")}
          >
            <Link to="/staffs">
              <GroupIcon style={{ color: "teal" }} /> Staff
            </Link>
          </li>
          <li
            className={activeLink === "/workers" ? "active" : ""}
            onClick={() => handleLinkClick("/workers")}
          >
            <Link to="/workers">
              <WorkIcon style={{ color: "red" }} /> Workers
            </Link>
          </li>
          <li
            className={activeLink === "/payments" ? "active" : ""}
            onClick={() => handleLinkClick("/payments")}
          >
            <Link to="/salaries">
              <PaymentIcon style={{ color: "gold" }} /> Salary
            </Link>
          </li>
          <li
            className={activeLink === "/maintenances" ? "active" : ""}
            onClick={() => handleLinkClick("/maintenances")}
          >
            <Link to="/maintenances">
              <BuildIcon style={{ color: "brown" }} /> Maintenance
            </Link>
          </li>
          <li
            className={activeLink === "/settings" ? "active" : ""}
            onClick={() => handleLinkClick("/settings")}
          >
            <Link to="/settings">
              <SettingsIcon style={{ color: "gray" }} /> Settings
            </Link>
          </li>
          <li onClick={handleLogoutClick}>
            <Link to="#">
              <LogoutIcon style={{ color: "white" }} /> Logout
            </Link>
          </li>
        </ul>
      </div>


      {/* logout dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to Log Out?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLogout} color="secondary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Sidebar;

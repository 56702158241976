import React, { useEffect, useState } from 'react';
import { getBookingById } from '../reducers/BookingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchStudentById } from '../reducers/StudentSlice';
import { fetchRoomById } from '../reducers/RoomSlice';

const ViewBookings = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { booking, status, error } = useSelector(state => state.bookings);
    const { student, studentStatus, studentError } = useSelector(state => state.students);
    const { room, roomStatus, roomError } = useSelector(state => state.rooms);
    const [currentDateTime, setCurrentDateTime] = useState('');

    useEffect(() => {
        if (id) {
            dispatch(getBookingById(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (booking?.data?.studentId) {
            dispatch(fetchStudentById(booking.data.studentId));
        }
    }, [booking, dispatch]);

    useEffect(() => {
        if (booking?.data?.selectedRoom) {
            dispatch(fetchRoomById(booking.data.selectedRoom));
        }
    }, [booking, dispatch]);

    useEffect(() => {
        const date = new Date();
        setCurrentDateTime(date.toLocaleString());
    }, []);

    if (status === 'loading' || studentStatus === 'loading' || roomStatus === 'loading') {
        return <div className="d-flex justify-content-center align-items-center h-100"><CircularProgress /></div>;
    }

    if (status === 'failed' || studentStatus === 'failed' || roomStatus === 'failed') {
        return <div>Failed to load data: {error || studentError || roomError}</div>;
    }

    if (!booking) {
        return <div>No booking data available.</div>;
    }

    const getMonthName = (monthNumber) => {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        // Ensure the monthNumber is valid
        return monthNumber >= 1 && monthNumber <= 12 ? monthNames[monthNumber - 1] : null;
    };
    const handlePrint = () => {
        const printWindow = window.open('', '_blank', 'width=800,height=600');
        const totalPaidAmount = booking?.data?.paymentHistory?.reduce(
            (sum, payment) => sum + (payment.amount || 0),
            0
        ) || 0;
        const remainingAmount = (booking?.data?.totalPayment || 0) - totalPaidAmount;

        const paymentHistoryRows = booking?.data?.paymentHistory?.length
            ? `
        <tr>
            <td>1</td>
            <td>${booking?.data?.paymentHistory.at(-1).amount.toLocaleString() || 'N/A'}</td>
            <td>${booking?.data?.paymentHistory.at(-1).date ? new Date(booking?.data?.paymentHistory.at(-1).date).toLocaleDateString() : 'N/A'}</td>
            <td>${booking?.data?.paymentHistory.at(-1).time || 'N/A'}</td>
            <td>${getMonthName(booking?.data?.paymentHistory.at(-1).month) || 'N/A'}</td>
        </tr>
      `
            : '<tr><td colspan="5">No Payment History Available</td></tr>';

        printWindow.document.write(`
            <html>
                <head>
                    <title>Booking Details</title>
                    <!-- Preconnect to Google Fonts -->
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                    <link href="https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu:wght@400..700&display=swap" rel="stylesheet">
                    <style>
                        body { font-family: "Poppins", serif; margin: 20px; color: #333; }
                        .container { max-width: 800px; margin: 0 auto; }
                        .same-divs { margin-bottom: 10px; }
                        .text-center { text-align: center; }
                        h3 { color: #333; margin-top: 20px; }
                        .table {
                            width: 100%;
                            border-collapse: collapse;
                            margin-top: 20px;
                        }
                        .table th, .table td {
                            border: 1px solid #ddd;
                            padding: 6px;
                            text-align: left;
                        }
                        .table th {
                            background-color: #f4f4f4;
                            font-weight: bold;
                        }
                        .table-striped tr:nth-child(even) {
                            background-color: #f9f9f9;
                        }
                        .date-time {
                            position: absolute; 
                            bottom: 10px; 
                            right: 20px; 
                            font-size: 14px; 
                            color: #333; 
                        }
                        /* Applying Noto Nastaliq Urdu font to Urdu text */
                        .noto-nastaliq-urdu-urdu-text {
                            font-family: "Noto Nastaliq Urdu", serif;
                            font-weight: 400;
                            font-style: normal;
                            direction: rtl; /* Ensures correct text direction for Urdu */
                            text-align: right;
                            font-display: swap; /* Ensures text is visible while loading */
                        }
                        /* Ensure proper font on print */
                        @media print {
                            body {
                            font-family: "Poppins", serif;
                            }
                            .noto-nastaliq-urdu-urdu-text {
                                font-family: "Noto Nastaliq Urdu", serif !important;
                                font-weight: 400;
                                font-style: normal;
                                direction: rtl;
                                font-optical-sizing: auto;
                            }
                        }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <h2 class="text-center">AK Boys Hostel</h2>
                        <h3 class="text-center">Booking Details</h3>
                        <table class="table">
                            <tr>
                                <th>Student ID</th>
                                <td>${student?._id || 'N/A'}</td>
                                <th>Student Name</th>
                                <td>${student?.studentName || 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Father Name</th>
                                <td>${student?.fatherName || 'N/A'}</td>
                                <th>Student Phone</th>
                                <td>${student?.contactNumber || 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Student Address</th>
                                <td>${student?.currentAddress || 'N/A'}</td>
                                <th>Room No</th>
                                <td>${room?.roomNumber || 'N/A'} 
                                    ${(room?.roomNumber && /Room (1|13|14|26|27)/.test(room?.roomNumber)) ? '(VIP)' : ''}
                                </td>
                            </tr>
                            <tr>
                                <th>Room Type</th>
                                <td>${room?.roomType || 'N/A'}</td>
                                <th>Room Capacity</th>
                                <td>${room?.capacity || 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Room Price</th>
                                <td>${room?.monthlyRent || 'N/A'}</td>
                                <th>Room Status</th>
                                <td>${room?.roomStatus || 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Booking ID</th>
                                <td>${booking?.data?._id || 'N/A'}</td>
                                <th>Booking Date</th>
                                <td>${booking?.data?.bookingDate ? new Date(booking?.data?.bookingDate).toLocaleDateString() : 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Time Periods</th>
                                <td>${(booking?.data?.durationValue && booking?.data?.durationType) ? `${booking?.data?.durationValue} ${booking?.data?.durationType}` : 'N/A'}</td>
                                <th>Advanced Payment</th>
                                <td>${booking?.data?.advancedPayment?.toLocaleString() || 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Total Paid Amount</th>
                                <td>${totalPaidAmount.toLocaleString()}</td>
                                <th>Total Payment</th>
                                <td>${booking?.data?.totalPayment?.toLocaleString() || 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Security Charges</th>
                                <td>${booking?.data?.securityCharges?.toLocaleString() || 'N/A'}</td>
                                <th>Remaining Amount</th>
                                <td>${remainingAmount >= 0 ? remainingAmount?.toLocaleString() : 'Error in Calculation'}</td>
                            </tr>
                            <tr>
                                <th>Payment Mode</th>
                                <td>${booking?.data?.paymentMode || 'N/A'}</td>
                                <th>Payment Status</th>
                                <td>${booking?.data?.paymentStatus || 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Security Payment Mode</th>
                                <td>${booking?.data?.securityPaymentMode || 'N/A'}</td>
                                <th></th>
                                <td>${''}</td>
                            </tr>
                        </table>
                        <h3>Payment History</h3>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Month</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${paymentHistoryRows}
                            </tbody>
                        </table>
                        <div class="date-time"><strong>Printed on:</strong> ${new Date().toLocaleString()}</div>
                        <div class="static-notes mt-4 p-3 border rounded noto-nastaliq-urdu-urdu-text">
                            <h3 class="text-primary noto-nastaliq-urdu-urdu-text">اہم ہدایات</h3>
                            <ul class="list-unstyled text-white">
                                <li class='noto-nastaliq-urdu-urdu-text'>ہاسٹل چھوڑنے سے دس دن قبل تحریری طور پر مطلع کرنا ہوگا۔</li>
                                <li class='noto-nastaliq-urdu-urdu-text'>ہاسٹل واجبات ہر ماہ کی 5 تاریخ تک جمع کروانے ہوں گے۔</li>
                                <li class='noto-nastaliq-urdu-urdu-text'>اچانک جانے والے طالب علم سے ایک ماہ کی فیس وصول کی جائے گی۔</li>
                                <li class='noto-nastaliq-urdu-urdu-text'>ہاسٹل کی کسی چیز کے نقصان کرنے پر اس چیز کی قیمت ادا کرنی ہوگی۔</li>
                            </ul>
                        </div>
                    </div>
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.print();
    };


    return (
        <div className="container my-4 text-white card-height">
            <div className="text-center mb-4">
                <h3 className="fw-bold">Booking Detail</h3>
            </div>
            <div className="row" id="roomContainer">
                <div className="col">
                    <div className="card-body">
                        <div className="card room-card p-4">
                            <h2 className="text-center text-white mb-2">AK Boys Hostel</h2>
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped text-center">
                                    <thead>
                                        <tr>
                                            <th>Label 1</th>
                                            <th>Data 1</th>
                                            <th>Label 2</th>
                                            <th>Data 2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Student ID</td>
                                            <td>{student?._id || 'N/A'}</td>
                                            <td>Student Name</td>
                                            <td>{student?.studentName || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Father Name</td>
                                            <td>{student?.fatherName || 'N/A'}</td>
                                            <td>Student Email</td>
                                            <td>{student?.email || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Student Phone</td>
                                            <td>{student?.contactNumber || 'N/A'}</td>
                                            <td>Student Address</td>
                                            <td>{student?.currentAddress || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Permanent Address</td>
                                            <td>{student?.permanentAddress || 'N/A'}</td>
                                            <td>Emergency Contact</td>
                                            <td>{student?.emergencyContact || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Birth</td>
                                            <td>{student?.dob ? new Date(student.dob).toLocaleDateString() : 'N/A'}</td>
                                            <td>Gender</td>
                                            <td>{student?.gender || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>CNIC</td>
                                            <td>{student?.cnic || 'N/A'}</td>
                                            <td>City</td>
                                            <td>{student?.city || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Blood Group</td>
                                            <td>{student?.bloodGroup || 'N/A'}</td>
                                            <td>Department</td>
                                            <td>{student?.department || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Current Year</td>
                                            <td>{student?.currentYear || 'N/A'}</td>
                                            <td>Semester</td>
                                            <td>{student?.semester || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Joining Date</td>
                                            <td>{student?.joinDate ? new Date(student.joinDate).toLocaleDateString() : 'N/A'}</td>
                                            <td>Room No</td>
                                            <td>
                                                {room?.roomNumber || 'N/A'}
                                                {room?.roomNumber && /Room (1|13|14|26|27)/.test(room?.roomNumber) && <span>(VIP)</span>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Room Type</td>
                                            <td>{room?.roomType || 'N/A'}</td>
                                            <td>Room Capacity</td>
                                            <td>{room?.capacity || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Room Price</td>
                                            <td>{room?.monthlyRent || 'N/A'}</td>
                                            <td>Room Status</td>
                                            <td>{room?.roomStatus || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Booking ID</td>
                                            <td>{booking?.data?._id || 'N/A'}</td>
                                            <td>Booking Date</td>
                                            <td>{booking?.data?.bookingDate ? new Date(booking?.data?.bookingDate).toLocaleDateString() : 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Time Periods</td>
                                            <td>{(booking?.data?.durationValue && booking?.data?.durationType) ? `${booking?.data?.durationValue} ${booking?.data?.durationType}` : 'N/A'}</td>
                                            <td>Advanced Payment</td>
                                            <td>{booking?.data?.advancedPayment?.toLocaleString() || 'N/A'}</td>
                                        </tr>
                                        {(() => {
                                            const totalPaidAmount = booking?.data?.paymentHistory?.reduce((sum, payment) => sum + (payment.amount || 0), 0) || 0;
                                            const remainingAmount = (booking?.data?.totalPayment || 0) - totalPaidAmount;

                                            return (
                                                <>
                                                    <tr>
                                                        <td>Total Paid Amount</td>
                                                        <td>{totalPaidAmount.toLocaleString()}</td>
                                                        <td>Total Payment</td>
                                                        <td>{booking?.data?.totalPayment?.toLocaleString() || 'N/A'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Security Charges</td>
                                                        <td>{booking?.data?.securityCharges?.toLocaleString() || 'N/A'}</td>
                                                        <td>Remaining Amount</td>
                                                        <td>{remainingAmount >= 0 ? remainingAmount.toLocaleString() : 'Error in Calculation'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Payment Mode</td>
                                                        <td>{booking?.data?.paymentMode || 'N/A'}</td>
                                                        <td>Payment Status</td>
                                                        <td>{booking?.data?.paymentStatus || 'N/A'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Security Payment Mode</td>
                                                        <td>{booking?.data?.securityPaymentMode || 'N/A'}</td>
                                                        <td></td>
                                                        <td>{''}</td>
                                                    </tr>
                                                </>
                                            );
                                        })()}
                                    </tbody>
                                </table>
                            </div>

                            <div className="payment-history-section">
                                <h3 className="text-center text-white mb-2">Payment History</h3>
                                <div className="table-responsive">
                                    {booking?.data?.paymentHistory && booking.data.paymentHistory.length > 0 ? (
                                        <table className="table table-bordered table-striped text-white">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Month</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {booking.data.paymentHistory.map((payment, index) => (
                                                    <tr key={payment._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{payment.amount.toLocaleString() || 'N/A'}</td>
                                                        <td>{payment.date ? new Date(payment.date).toLocaleDateString() : 'N/A'}</td>
                                                        <td>{payment.time || 'N/A'}</td>
                                                        <td>{getMonthName(payment.month) || 'N/A'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div>No Payment History Available</div>
                                    )}
                                </div>
                            </div>
                            <div className="static-notes mt-4 p-3 border rounded noto-nastaliq-urdu-urdu-text">
                                <h3 className="text-primary noto-nastaliq-urdu-urdu-text">اہم ہدایات</h3>
                                <ul className="list-unstyled text-white">
                                    <li className='noto-nastaliq-urdu-urdu-text'>ہاسٹل چھوڑنے سے دس دن قبل تحریری طور پر مطلع کرنا ہوگا۔</li>
                                    <li className='noto-nastaliq-urdu-urdu-text'>ہاسٹل واجبات ہر ماہ کی 5 تاریخ تک جمع کروانے ہوں گے۔</li>
                                    <li className='noto-nastaliq-urdu-urdu-text'>اچانک جانے والے طالب علم سے ایک ماہ کی فیس وصول کی جائے گی۔</li>
                                    <li className='noto-nastaliq-urdu-urdu-text'>ہاسٹل کی کسی چیز کے نقصان کرنے پر اس چیز کی قیمت ادا کرنی ہوگی۔</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="print-btn text-center">
                <button onClick={handlePrint} className="btn btn-primary mt-4">Print Booking Details</button>
            </div>
        </div>
    );
};

export default ViewBookings;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaTrashCan } from "react-icons/fa6";
import { MdOutlineKeyboardAlt, MdOutlineBorderColor } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaffMembers, setSearchQuery, setCurrentPage, deleteStaff } from "../reducers/StaffSlice";
import { toast } from 'react-toastify';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import nodata from '../assets/images/nodata.webp'

const Staff = () => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false)
  const [staffToDelete, setStaffToDelete] = useState(null)
  const [searchQuery, setSearchQuery] = useState("");
  const { staffMembers, totalStaff, totalPages, currentPage, status } = useSelector(
    (state) => state.staffs
  );

  useEffect(() => {
    dispatch(fetchStaffMembers({ page: currentPage, searchQuery }));
  }, [dispatch, currentPage, searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    dispatch(fetchStaffMembers({ page: 1, searchQuery: event.target.value }));
  };

  const handlePaginationChange = (page) => {
    if (page > 0 && page <= totalPages) {
      dispatch(setCurrentPage(page));
    }
  };

  const handleDeleteClick = (studentId) => {
    setStaffToDelete(studentId)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setStaffToDelete(null)
  }

  const handleConfirmDelete = async () => {
    if (staffToDelete) {
      try {
        await dispatch(deleteStaff(staffToDelete)).unwrap();
        await dispatch(fetchStaffMembers({ page: currentPage, searchQuery: '' }));
        toast.success('Staff deleted successfully!');
      } catch (error) {
        toast.error('Failed to delete the Staff. Please try again.');
      } finally {
        setOpenDialog(false);
        setStaffToDelete(null);
      }
    }
  };

  return (
    <>
      <div className="table-header d-flex justify-content-between mt-3 p-3">
        <div className="total-count">
          <h6>Total Staff: {totalStaff}</h6>
        </div>
        <div className="search-box">
          <div className="search-box">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="add-btn">
          <Link to="/staffs/add-staff">
            <button className="btn btn-primary text-white">Add Staff</button>
          </Link>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr #</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">CNIC</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {status === "loading" ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <CircularProgress />
                </td>
              </tr>
            ) : staffMembers.length > 0 ? (
              staffMembers.map((staff, index) => (
                <tr key={staff._id}>
                  <th scope="row">{(currentPage - 1) * 10 + index + 1}</th>
                  <td>{staff.fullName}</td>
                  <td>{staff.email}</td>
                  <td>{staff.phone}</td>
                  <td>{staff.cnic}</td>
                  <td className="action-flex">
                    <Link to={`/staffs/view-staff/${staff._id}`}>
                      <MdOutlineKeyboardAlt className="keyboard" />
                    </Link>
                    <Link to={`/staffs/edit-staff/${staff._id}`}>
                      <MdOutlineBorderColor className="edit" />
                    </Link>
                    <button className="btn btn-link p-0">
                      <FaTrashCan className="delete" onClick={() => handleDeleteClick(staff._id)} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
              <td colSpan="6" className="no-data">
                <img src={nodata} alt="No Data" />
              </td>
            </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-1 d-flex justify-content-center gap-3 align-items-center">
        <button
          className="pagination-btn"
          onClick={() => handlePaginationChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button
          className="pagination-btn"
          onClick={() => handlePaginationChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Staff?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Staff;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify'; 
import { fetchWorkerById, updateWorker } from '../reducers/WorkerSlice'; 

const UpdateWorker = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        dob: '',
        gender: '',
        address: '',
        cnic: '',
        jobRole: '',
        department: '',
        joinDate: '',
        shiftTiming: '',
        salary: '',
        image: null,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchWorkerData = async () => {
            try {
                const worker = await dispatch(fetchWorkerById(id)).unwrap();
                setFormData({
                    fullName: worker.fullName || '',
                    email: worker.email || '',
                    phone: worker.phone || '',
                    dob: worker.dob || '',
                    gender: worker.gender || '',
                    address: worker.address || '',
                    cnic: worker.cnic || '',
                    jobRole: worker.jobRole || '',
                    department: worker.department || '',
                    joinDate: worker.joinDate || '',
                    shiftTiming: worker.shiftTiming || '',
                    salary: worker.salary || '',
                    image: null
                });
            } catch (error) {
                toast.error('Failed to fetch worker details. Please try again.');
            }
        };
        fetchWorkerData();
    }, [id, dispatch]);

    const handleChange = (e) => {
        const { id, value, files } = e.target;
        setFormData({
            ...formData,
            [id]: files ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const workerData = new FormData();
        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                workerData.append(key, formData[key]);
            }
        });

        try {
            await dispatch(updateWorker({ id, data: workerData })).unwrap();
            setLoading(false);
            navigate('/workers');
            toast.success('Worker updated successfully!');
        } catch (error) {
            setLoading(false);
            toast.error('Failed to update worker. Please try again.');
        }
    };

    return (
        <div className="container mt-4 text-white">
            <h2 className="mb-4 text-center">Update Worker</h2>
            <form onSubmit={handleSubmit}>
                {/* Row 1 */}
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="fullName" className="form-label">Full Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="fullName"
                            placeholder="Enter full name"
                            value={formData.fullName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="phone" className="form-label">Phone</label>
                        <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            placeholder="Enter phone number"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/* Row 2 */}
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="dob" className="form-label">Date of Birth</label>
                        <input
                            type="date"
                            className="form-control"
                            id="dob"
                            value={formData.dob ? new Date(formData.dob).toISOString().split('T')[0] : ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="gender" className="form-label">Gender</label>
                        <select
                            className="form-select"
                            id="gender"
                            value={formData.gender}
                            onChange={handleChange}
                        >
                            <option value="">Select gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="address" className="form-label">Address</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            placeholder="Enter address"
                            value={formData.address}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/* Row 3 */}
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="jobRole" className="form-label">Job Role</label>
                        <input
                            type="text"
                            className="form-control"
                            id="jobRole"
                            placeholder="Enter job role"
                            value={formData.jobRole}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="department" className="form-label">Department</label>
                        <input
                            type="text"
                            className="form-control"
                            id="department"
                            placeholder="Enter department"
                            value={formData.department}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="joinDate" className="form-label">Joining Date</label>
                        <input
                            type="date"
                            className="form-control"
                            id="joinDate"
                            value={formData.joinDate ? new Date(formData.joinDate).toISOString().split('T')[0] : ''}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/* Row 4 */}
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="shiftTiming" className="form-label">Shift Timing</label>
                        <input
                            type="text"
                            className="form-control"
                            id="shiftTiming"
                            placeholder="e.g., 9:00 AM - 5:00 PM"
                            value={formData.shiftTiming}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="salary" className="form-label">Salary</label>
                        <input
                            type="number"
                            className="form-control"
                            id="salary"
                            placeholder="Enter salary"
                            value={formData.salary}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="cnic" className="form-label">CNIC/ID Number</label>
                        <input
                            type="text"
                            className="form-control"
                            id="cnic"
                            placeholder="Enter ID number"
                            value={formData.cnic}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/* Row 5 */}
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="image" className="form-label">Photo</label>
                        <input
                            type="file"
                            className="form-control"
                            id="image"
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/* Submit Button */}
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-primary text-white"
                            disabled={loading}
                        >
                            {loading ? (
                                <span>
                                    <span className="spinner-border spinner-border-sm me-2"></span>
                                    Updating...
                                </span>
                            ) : (
                                'Update Worker'
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UpdateWorker;

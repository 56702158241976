import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllWorkers } from '../reducers/WorkerSlice';
import { fetchAllStaffMembers } from '../reducers/StaffSlice';

const AddSalary = () => {
    const dispatch = useDispatch();
    const { staffMembers } = useSelector((state) => state.staffs);
    const { workers } = useSelector((state) => state.workers);

    useEffect(() => {
        dispatch(fetchAllWorkers());
        dispatch(fetchAllStaffMembers());
    }, [dispatch]);

    const [formData, setFormData] = useState({
        employeeType: '',
        employeeId: '',
        fullName: '',
        basicSalary: '',
        allowances: '',
        deductions: '',
        netSalary: '',
        salaryMonth: '',
        paymentMode: '',
        paymentDate: '',
    });
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { id, value } = e.target;

        setFormData((prevData) => {
            const updatedData = { ...prevData, [id]: value };

            if (id === 'employeeId') {
                const selectedEmployee =
                    formData.employeeType === 'Worker'
                        ? workers.find((worker) => worker._id === value)
                        : staffMembers.find((staff) => staff._id === value);

                if (selectedEmployee) {
                    updatedData.fullName = selectedEmployee.fullName || '';
                    updatedData.basicSalary = selectedEmployee.salary || 0;
                    updatedData.netSalary = selectedEmployee.salary || 0;
                }
            }

            if (id === 'basicSalary' || id === 'allowances' || id === 'deductions') {
                const basicSalary = parseFloat(updatedData.basicSalary) || 0;
                const allowances = parseFloat(updatedData.allowances) || 0;
                const deductions = parseFloat(updatedData.deductions) || 0;
                updatedData.netSalary = basicSalary + allowances - deductions;
            }

            return updatedData;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        // Simulate API call
        setTimeout(() => {
            console.log('Form Data:', formData);  // Send the form data including fullName
            setLoading(false);
        }, 1000);
    };

    const employeeList =
        formData.employeeType === 'Worker'
            ? workers
            : formData.employeeType === 'Staff'
                ? staffMembers
                : [];

    return (
        <div className="container mt-4 text-white">
            <h2 className="mb-4 text-center">Add Salary Data</h2>
            <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="employeeType" className="form-label">Employee Type</label>
                        <select
                            className="form-select"
                            id="employeeType"
                            value={formData.employeeType}
                            onChange={handleInputChange}
                        >
                            <option value="">Select employee type</option>
                            <option value="Worker">Worker</option>
                            <option value="Staff">Staff</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="employeeId" className="form-label">Employee</label>
                        <select
                            className="form-select"
                            id="employeeId"
                            value={formData.employeeId}
                            onChange={handleInputChange}
                            disabled={!formData.employeeType}
                        >
                            <option value="">Select an employee</option>
                            {employeeList.map((employee) => (
                                <option key={employee._id} value={employee._id}>
                                    {employee.fullName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="salaryMonth" className="form-label">Salary Month</label>
                        <input
                            type="month"
                            className="form-control"
                            id="salaryMonth"
                            value={formData.salaryMonth}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="basicSalary" className="form-label">Basic Salary</label>
                        <input
                            type="number"
                            className="form-control"
                            id="basicSalary"
                            value={formData.basicSalary}
                            onChange={handleInputChange}
                            placeholder="Enter basic salary"
                            readOnly
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="allowances" className="form-label">Allowances</label>
                        <input
                            type="number"
                            className="form-control"
                            id="allowances"
                            value={formData.allowances}
                            onChange={handleInputChange}
                            placeholder="Enter allowances"
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="deductions" className="form-label">Deductions</label>
                        <input
                            type="number"
                            className="form-control"
                            id="deductions"
                            value={formData.deductions}
                            onChange={handleInputChange}
                            placeholder="Enter deductions"
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="netSalary" className="form-label">Net Salary</label>
                        <input
                            type="number"
                            className="form-control"
                            id="netSalary"
                            value={formData.netSalary}
                            readOnly
                            placeholder="Net salary will be calculated"
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="paymentDate" className="form-label">Payment Date</label>
                        <input
                            type="date"
                            className="form-control"
                            id="paymentDate"
                            value={formData.paymentDate}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="paymentMode" className="form-label">Payment Mode</label>
                        <select
                            className="form-select"
                            id="paymentMode"
                            value={formData.paymentMode}
                            onChange={handleInputChange}
                        >
                            <option value="">Select payment mode</option>
                            <option value="Bank Transfer">Bank Transfer</option>
                            <option value="Cash">Cash</option>
                            <option value="Cheque">Cheque</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-primary text-white"
                            disabled={loading}
                        >
                            {loading ? (
                                <span>
                                    <span className="spinner-border spinner-border-sm me-2"></span>
                                    Adding...
                                </span>
                            ) : (
                                'Add Salary Data'
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddSalary;

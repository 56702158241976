import React, { useEffect, useState } from 'react';
import { FaTrashCan } from 'react-icons/fa6';
import { MdOutlineKeyboardAlt, MdOutlineBorderColor } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteBooking, getBookings } from '../reducers/BookingSlice';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import { toast } from 'react-toastify';
import { fetchAllStudents } from '../reducers/StudentSlice';
import { fetchAllRooms } from '../reducers/RoomSlice';
import CircularProgress from '@mui/material/CircularProgress';
import nodata from '../assets/images/nodata.webp'


const Bookings = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const { students } = useSelector((state) => state.students);
  const { rooms } = useSelector((state) => state.rooms);

  useEffect(() => {
    dispatch(fetchAllStudents());
    dispatch(fetchAllRooms());
  }, [dispatch]);

  const {
    bookings,
    currentPage,
    totalPages,
    totalBookings,
    limit,
    loading
  } = useSelector((state) => state.bookings);
  const [openDialog, setOpenDialog] = useState(false)
  const [bookingToDelete, setBookingToDelete] = useState(null)

  useEffect(() => {
    dispatch(getBookings({ page: currentPage, searchQuery }));
  }, [dispatch, currentPage, searchQuery]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      dispatch(getBookings({ page: newPage, searchQuery }));
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setBookingToDelete(null)
  }
  const handleDeleteClick = (id) => {
    setBookingToDelete(id)
    setOpenDialog(true)
  }

  const handleConfirmDelete = async () => {
    if (bookingToDelete) {
      try {
        await dispatch(deleteBooking(bookingToDelete)).unwrap();
        toast.success('Booking deleted successfully!');
        await dispatch(getBookings({ page: currentPage, searchQuery: '' }));
      } catch (error) {
        toast.error('Failed to delete the booking. Please try again.');
      } finally {
        setOpenDialog(false);
        setBookingToDelete(null);
      }
    }
  };

  const getStudentName = (studentId) => {
    const student = students.find((student) => student._id === studentId);
    return student ? student?.studentName : 'N/A';
  };

  const getRoomDetails = (roomId) => {
    const room = rooms.find((room) => room._id === roomId);
    return room ? room : { roomNumber: 'N/A', roomType: 'N/A', floorNumber: 'N/A' };
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    dispatch(getBookings({ page: 1, searchQuery: event.target.value }));
  };

  return (
    <>
      <div className="booking-container">
        <div className="table-header d-flex justify-content-between align-items-center p-3">
          <div className="total-count">
            <h6 className='mb-0'>Total Bookings ({totalBookings})</h6>
          </div>
          <div className="search-box">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="add-btn">
            <Link to="/bookings/add-bookings">
              <button className="btn btn-primary text-white">Add Bookings</button>
            </Link>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Student Name</th>
                <th scope="col">Room No</th>
                <th scope="col">Room Type</th>
                <th scope="col">Floor No</th>
                <th scope="col">Room Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7" className="loading-container">
                    <CircularProgress />
                  </td>
                </tr>
              ) : bookings.length > 0 ? (
                bookings.map((booking, index) => {
                  // Extracting the room details based on the selectedRoom
                  const roomDetails = getRoomDetails(booking.selectedRoom);

                  return (
                    <tr key={booking._id}>
                      <td scope="row">{(currentPage - 1) * limit + index + 1}</td>
                      <td>{booking.studentName}</td>
                      <td>{roomDetails.roomNumber}</td>
                      <td>{roomDetails.roomType}</td>
                      <td>{roomDetails.floorNumber}</td>
                      <td>{roomDetails.roomStatus}</td>
                      <td className="action-flex">
                        <Link to={`/bookings/view-booking/${booking._id}`}>
                          <MdOutlineKeyboardAlt className="keyboard" />
                        </Link>
                        <Link to={`/bookings/edit-booking/${booking._id}`}>
                          <MdOutlineBorderColor className="edit" />
                        </Link>
                        <FaTrashCan
                          className="delete"
                          onClick={() => handleDeleteClick(booking._id)}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" className="no-data">
                    <img src={nodata} alt="No Data" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination-1 d-flex justify-content-center gap-3 align-items-center">
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>


      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this student?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Bookings;

import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { fetchStudentById, updateStudent } from '../reducers/StudentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function EditStudent() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { student, status } = useSelector(state => state.students);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    studentName: '',
    fatherName: '',
    email: '',
    gender: '',
    cnic: '',
    joinDate: '',
    city: '',
    contactNumber: '',
    currentAddress: '',
    permanentAddress: '',
    emergencyContact: '',
    department: '',
    currentYear: '',
    semester: '',
    bloodGroup: '',
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchStudentById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (student) {
      setFormData({
        studentName: student.studentName || '',
        fatherName: student.fatherName || '',
        email: student.email || '',
        gender: student.gender || '',
        cnic: student.cnic || '',
        joinDate: student.joinDate ? new Date(student.joinDate).toISOString().split('T')[0] : '',
        city: student.city || '',
        contactNumber: student.contactNumber || '',
        currentAddress: student.currentAddress || '',
        permanentAddress: student.permanentAddress || '',
        emergencyContact: student.emergencyContact || '',
        department: student.department || '',
        currentYear: student.currentYear || '',
        semester: student.semester || '',
        bloodGroup: student.bloodGroup || '',
      });
    }
  }, [student]);

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedStudentData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        updatedStudentData.append(key, formData[key]);
      }
    });

    try {
      const response = await dispatch(updateStudent({ id, studentData: updatedStudentData }));

      if (response) {
        toast.success('Student has been updated!');
        navigate('/students');
      } else {
        toast.error('An error occurred while updating the student.');
      }
    } catch (error) {
      toast.error('Update failed!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container mt-5 text-white py-3">
        <h2 className="text-center mb-4">Edit Student</h2>
        <form onSubmit={handleSubmit}>
          <div className="row g-3 mb-3">
            <div className="col-md-4">
              <label htmlFor="studentName" className="form-label">Student Name</label>
              <input
                type="text"
                className="form-control"
                id="studentName"
                value={formData.studentName}
                onChange={handleChange}
                placeholder="Enter Student Name"
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="fatherName" className="form-label">Father Name</label>
              <input
                type="text"
                className="form-control"
                id="fatherName"
                value={formData.fatherName}
                onChange={handleChange}
                placeholder="Enter Father Name"
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter email"
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="studentPic" className="form-label">Student Picture</label>
              <input
                type="file"
                className="form-control"
                id="studentPic"
                onChange={handleChange}
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="gender" className="form-label">Gender</label>
              <select
                className="form-select"
                id="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="col-md-4">
              <label htmlFor="joinDate" className="form-label">Joining Date</label>
              <input
                type="date"
                className="form-control"
                id="joinDate"
                name="joinDate"
                onChange={handleChange}
                value={formData.joinDate}
              />
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col-md-4">
              <label htmlFor="cnic" className="form-label">Student CNIC</label>
              <input
                type="text"
                className="form-control"
                id="cnic"
                value={formData.cnic}
                onChange={handleChange}
                placeholder="Enter CNIC"
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="city" className="form-label">City</label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                placeholder="Enter city"
                onChange={handleChange}
                value={formData.city}
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="contactNumber" className="form-label">Contact Number</label>
              <input
                type="tel"
                className="form-control"
                id="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                placeholder="Enter Contact Number"
              />
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col-md-4">
              <label htmlFor="currentAddress" className="form-label">Current Address</label>
              <input
                type="text"
                className="form-control"
                id="currentAddress"
                value={formData.currentAddress}
                onChange={handleChange}
                placeholder="Enter Current Address"
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="permanentAddress" className="form-label">Permanent Address</label>
              <input
                type="text"
                className="form-control"
                id="permanentAddress"
                value={formData.permanentAddress}
                onChange={handleChange}
                placeholder="Enter Permanent Address"
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="emergencyContact" className="form-label">Emergency Contact Number</label>
              <input
                type="tel"
                className="form-control"
                id="emergencyContact"
                value={formData.emergencyContact}
                onChange={handleChange}
                placeholder="Enter Emergency Contact Number"
              />
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col-md-4">
              <label htmlFor="department" className="form-label">Department</label>
              <input
                type="text"
                className="form-control"
                id="department"
                name="department"
                placeholder="Enter department"
                onChange={handleChange}
                value={formData.department}
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="currentYear" className="form-label">Current Year</label>
              <input
                type="number"
                className="form-control"
                id="currentYear"
                value={formData.currentYear}
                onChange={handleChange}
                placeholder="Enter Current Year"
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="semester" className="form-label">Semester</label>
              <input
                type="number"
                className="form-control"
                id="semester"
                value={formData.semester}
                onChange={handleChange}
                placeholder="Enter Semester"
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="bloodGroup" className="form-label">Blood Group</label>
              <input
                type="text"
                className="form-control"
                id="bloodGroup"
                value={formData.bloodGroup}
                onChange={handleChange}
                placeholder="Enter Blood Group"
              />
            </div>
          </div>

          <div className="text-center d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary text-white"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <span className="spinner-border spinner-border-sm me-2"></span>
                  Updating...
                </span>
              ) : (
                'Update Student'
              )}
            </button>
          </div>

        </form>
      </div>
    </>
  )
}

export default EditStudent
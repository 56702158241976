import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { fetchRoomById } from '../reducers/RoomSlice';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

const ViewRooms = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { room, status, error } = useSelector(state => state.rooms);

    useEffect(() => {
        if (id) {
            dispatch(fetchRoomById(id));
        }
    }, [id, dispatch]);

    if (status === 'loading') {
        return <div className="d-flex justify-content-center align-items-center h-100"><CircularProgress /></div>;
    }

    if (status === 'failed') {
        return <div>Failed to load room data: {error}</div>;
    }

    if (!room) {
        return <div>No room data available.</div>;
    }

    return (
        <>
            <div className="container my-4 text-white view-room">
                <div className="text-center mb-4">
                    <h3 className="fw-bold">Room Detail</h3>
                </div>
                <div className="row" id="roomContainer">
                    <div className="col">
                        <div className="card-body">
                            <div className="card room-card p-4">
                                <h2 className='text-center text-white mb-2'>AK Boys Hostel</h2>
                                <div className="same-divs"><strong>Room ID:</strong> {room._id || 'N/A'}</div>
                                <div className="same-divs"><strong>Room NO:</strong> {room.roomNumber || 'N/A'}</div>
                                <div className="same-divs"><strong>Room Type:</strong> {room.roomType || 'N/A'}</div>
                                <div className="same-divs"><strong>Floor NO:</strong> {room.floorNumber || 'N/A'}</div>
                                <div className="same-divs"><strong>Capacity:</strong> {room.capacity || 'N/A'}</div>
                                <div className="same-divs"><strong>Available Beds:</strong> {room.availableBeds || 'N/A'}</div>
                                <div className="same-divs"><strong>Monthly Rent:</strong> {room.monthlyRent ? `${room.monthlyRent} PKR` : 'N/A'}</div>
                                <div className="same-divs"><strong> Room Status:</strong> {room.roomStatus || 'N/A'}</div>
                                <div className="same-divs"><strong>Available From:</strong> {room.availableFrom || 'N/A'}</div>
                                <div className="same-divs"><strong>Facilities:</strong> {room.facilities?.join(', ') || 'N/A'}</div>
                                <div className="same-divs"><strong>Notes:</strong> {room.notes || 'N/A'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewRooms;
